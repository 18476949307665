import axios from "axios";

const API_JANUS_URL = process.env.REACT_APP_JANUS_API_URL;
console.log(API_JANUS_URL);
const apiJanus = axios.create({
  baseURL: API_JANUS_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default apiJanus;
