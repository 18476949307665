import styled from "styled-components";

export const Container = styled.div`
  max-width: 80rem;
  margin: 10rem auto;
  padding: 0 1rem;

  gap: 2rem;
  display: flex;
  justify-content: center;

  position: relative;
`;

export const AuthContainer = styled.div`
  max-width: 50rem;
  max-height: 50rem;

  padding: 3rem;

  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--gray-50);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 1rem;

  h1 {
    font-size: 3rem;
  }
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;

  button {
    font-weight: bold;

    width: 5rem;
    height: 2rem;

    color: var(--white);
    background-color: var(--black);

    border: none;
    border-radius: 0.3rem;

    transition: background 200ms;
    cursor: pointer;

    :hover {
      background: var(--primary);
    }
  }
`;

export const Img = styled.img`
  width: 1.5rem;
  height: 2rem;
`;

export const FormAuth = styled.form`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin-bottom: 1rem;
  span {
    color: red;
    font-size: 0.8rem;
  }
  input {
    height: 3rem;
    width: 20rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-75);
    padding: 1rem;
    transition: border 200ms;
    ::placeholder {
      color: var(--gray-88);
      justify-content: center;
      align-items: center;
    }

    :focus {
      outline: none;
      border: 1px solid var(--gray-150);
    }
    :focus::placeholder {
      color: var(--gray-150);
    }
    @media screen and (max-width: 500px) {
      width: 16rem;
    }
  }

  label {
    font-family: "Visby-demi-bold";
  }
`;
