import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContentText = styled.div`
  padding: 5rem 10rem;

  p {
    font-size: 1.3rem;
    color: var(--gray-95);
    text-align: start;
    letter-spacing: 0rem;
    line-height: 1.5rem;

    @media screen and (max-width: 768px) {
      text-align: justify;
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem 2.5rem;
  }
`;

export const Title = styled.h1`
  @media screen and (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ParagraphTitle = styled.h5`
  font-weight: bold;
  font-size: 2rem;
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    margin: 1rem 0;
    font-size: 1.3rem;
  }
`;

export const ContentTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 1.3rem;
  line-height: 1.3rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 2rem;

    @media screen and (max-width: 768px) {
      padding: 0.9rem;
    }
  }

  th {
    font-family: "Visby-demi-bold";
    text-align: center;
  }

  td {
    font-family: "Visby-regular";
    text-align: center;
    color: var(--gray-95);
    line-height: 1.5rem;
  }

  .table_td {
    color: var(--black);
    font-family: "Visby-demi-bold";
    background-color: var(--primary);
  }

  .type_table {
    background-color: #303030;
    color: white;
  }
`;
