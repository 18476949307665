import cryptojs from "crypto-js";
import { jwtVerify } from "jose";

const cryptoSecret = process.env.REACT_APP_CRYPTO_SECRET as string;
const TOKEN_SECRET_KEY = process.env.REACT_APP_ACCESS_TOKEN_SECRET as string;

const tokenPath = "@website:access_token";
const permissionsPath = "@website:permissions";

function decodeCipherTextAndParse<T>(ciphertext: string): T {
  const bytes = cryptojs.AES.decrypt(ciphertext, cryptoSecret);
  const originalText = bytes.toString(cryptojs.enc.Utf8);
  return JSON.parse(originalText);
}

export const setTokenOnStorage = (payload: string) => {
  const ciphertext = cryptojs.AES.encrypt(
    JSON.stringify({ access_token: payload }),
    cryptoSecret
  ).toString();
  localStorage.setItem(tokenPath, ciphertext);
};

export const getTokenOnStorage = (): string | undefined => {
  try {
    const ciphertext = localStorage.getItem(tokenPath);
    if (!ciphertext) return;

    const token = decodeCipherTextAndParse<{ access_token: string }>(
      ciphertext
    ).access_token;

    return token;
  } catch {
    return undefined;
  }
};

export const removeTokenOnStorage = (): void => {
  localStorage.removeItem(tokenPath);
  localStorage.removeItem(permissionsPath);
};

export const verifyToken = async (): Promise<boolean> => {
  try {
    const accessToken = getTokenOnStorage();

    if (accessToken === null) {
      return false;
    }
    await jwtVerify(
      accessToken as string,
      new TextEncoder().encode(TOKEN_SECRET_KEY)
    );
    return true;
  } catch (error) {
    console.log({
      message: "Error to verify token",
      error,
    });
    return false;
  }
};

export const setPermissinosOnStorage = (payload: string[]) => {
  const ciphertext = cryptojs.AES.encrypt(
    JSON.stringify(payload),
    cryptoSecret
  ).toString();
  localStorage.setItem(permissionsPath, ciphertext);
};

export const getPermissionsOnStorage = (): string[] | undefined => {
  try {
    const ciphertext = localStorage.getItem(permissionsPath);
    if (!ciphertext) return;

    const permissions = decodeCipherTextAndParse<string[]>(ciphertext);

    return permissions;
  } catch {
    return undefined;
  }
};

export const hasPermissions = (permission: string): boolean => {
  const permissions = getPermissionsOnStorage();
  if (permissions) {
    return permissions.some((_permission) => permission === _permission);
  } else {
    return false;
  }
};
