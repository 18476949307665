import { Helmet } from "react-helmet-async";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Container, ContentText, Subtitle, Title } from "./styles";

export const Regulamento = () => {
  return (
    <>
      <Helmet>
        <title>The Best Açaí - Regulamento</title>
        <link rel="canonical" href={`https://thebestacai.com.br/regulamento`} />
      </Helmet>
      <Navbar />
      <Container>
        <ContentText>
          <Title>Clube The Best - Regulamento</Title>
          <p>
            <strong> 1.1.</strong> Objetiva-se, com os termos aqui mencionados,
            regulamentar o Programa “Clube The Best” (ou “Programa de
            Recompensa”) instituído e promovido pelo{" "}
            <strong>GRUPO THE BEST FRANCHISING LTDA</strong>, pessoa jurídica de
            direito privado, inscrita no CNPJ sob o nº 28.967.949/0001-84, com
            sede na Rodovia Engenheiro Ângelo Lopes, s/n, sala A, Parque
            Industrial Nene Favoreto, CEP: 86.200-000, na cidade de Ibiporã,
            estado do Paraná, por meio do qual os clientes (“Participante”) do
            “The Best Açaí” poderão conquistar o direito de recompensas,
            mediante o cumprimento das regras previstas neste Regulamento.
          </p>
          <br />
          <p>
            <strong>1.2.</strong> O(A) Participante, que reside fora das cidades
            participantes do Programa, mas possui acesso a uma unidade do The
            Best Açaí, fica ciente que, ao alcançar os pontos necessários,
            precisará diligenciar-se, pessoalmente, em qualquer unidade
            franqueada para retirar a recompensa, sujeita à disponibilidade, não
            sendo possível receber o prêmio por frete.
          </p>
          <br />
          <p>
            <strong>2.1. </strong>Cronograma da Programa de Recompensas: prazo
            indeterminado e/ou enquanto durarem os estoques dos itens aqui
            previstos podendo, ainda, o The Best Açaí, encerrar o programa a seu
            único e exclusivo critério, mediante comunicação prévia em suas
            redes sociais, aplicativo The Best Club ou outro meio idôneo.
          </p>
          <br />
          <p>
            <strong>3.1.</strong> O Programa de Recompensas do The Best Açaí é
            aberto a qualquer pessoa física, consumidor final, residente e
            domiciliado(a) em território nacional, que, a partir da data de 05
            de setembro de 2023, realizar compras presenciais em lojas físicas
            próprias e/ou franqueadas do The Best Açaí, no valor mínimo de R$
            5,00 (cinco reais), e que se inscreva dentro das regras previstas no
            Programa.
          </p>
          <br />
          <p>
            <strong>3.2.</strong> As pessoas mencionadas acima, quando
            identificadas e/ou ainda aqueles que de alguma maneira manipularam,
            violaram ou fraudaram este regulamento para participar do Programa
            de Recompensas do The Best Açaí, não terão direito à recompensa.
          </p>
          <br />
          <Subtitle>CONDIÇÃO DE PARTICIPAÇÃO</Subtitle>
          <br />
          <p>
            <strong>4.1. </strong>A cada R$ 5,00 (cinco reais) e seus múltiplos
            em compras realizadas nas lojas físicas do The Best Açaí, o(a)
            Participante terá direito a 01 (um) ponto de recompensa, mediante o
            escaneamento do QRCode estampado no cupom fiscal de venda gerado, no
            momento da compra, junto ao aplicativo “Clube The Best”, após a
            realização gratuita do cadastro do cliente no respectivo aplicativo
            e o prosseguimento nos moldes descritos logo abaixo.
          </p>
          <br />
          <p>
            <strong>4.2.</strong> Não é possível utilizar pontos de recompensa a
            partir de compras concretizadas em lojas do The Best Açaí por meio
            do aplicativo do iFood. O presente Programa aplicar-se-á somente às
            compras operacionalizadas nas lojas físicas do The Best Açaí e suas
            franqueadas.
          </p>
          <br />
          <p>
            <strong>5.1.</strong> Para participar do Programa, os consumidores
            deverão instalar o aplicativo “Clube The Best” em seu dispositivo
            móvel e/ou ler o QRCode do cupom da venda gerada na compra e,
            inicialmente, informar o número de seu Cadastro Nacional de Pessoas
            Físicas do Ministério da Fazenda (CPF/MF).
          </p>
          <br />
          <p>
            <strong>5.2.</strong> Caso o(a) Participante já esteja cadastrado
            no aplicativo “Clube The Best”, o(a) consumidor(a) sempre será
            redirecionado(a) a uma página de avaliação do atendimento para,
            após, receber o direito da pontuação. Caso o cliente não tenha
            cadastro, será necessário realizar este procedimento em que o(a)
            Participante deverá fornecer todos os dados pessoais solicitados,
            sendo logo após direcionado para a avaliação do atendimento.
          </p>
          <br />
          <p>
            <strong>5.3.</strong> Após a realização completa da avaliação no
            aplicativo “Clube The Best”, basta o(a) participante escanear o
            QRCode do cupom fiscal da venda gerada na compra no referido
            aplicativo e inseri-lo corretamente no aplicativo, para
            contabilização dos pontos.
          </p>
          <br />
          <p>
            <strong>6.1.</strong> O cadastro é pessoal, individual e
            intransferível, e será utilizado como forma de identificação do
            consumidor para fins de registro de cupons fiscais emitidos pelas
            lojas físicas participantes do The Best Açaí, nos termos do presente
            regulamento.
          </p>
          <br />
          <p>
            <strong>7.1.</strong> O consumidor garante que as informações
            prestadas no cadastro possuem total veracidade, assumindo inteira
            responsabilidade pelo teor. Serão automaticamente desclassificados e
            excluídos do Programa, sem necessidade de prévia comunicação, os
            Participantes que informarem dados cadastrais incorretos, falsos ou
            incompletos e/ou que praticarem qualquer tipo de ato considerado
            fraudulento, ilegal, ilícito, ou que atente contra os objetivos
            deste Programa e do seu regulamento, sem exclusão das penalidades
            cabíveis.
          </p>
          <br />
          <p>
            <strong>8.1.</strong> É de exclusiva responsabilidade do(a)
            Participante a realização correta do cadastramento no aplicativo
            “Clube The Best”, bem como da inclusão no referido aplicativo do
            QRCode presente no cupom da venda gerada na compra, de acordo com as
            instruções descritas neste regulamento, sob pena de não participação
            do Programa.
          </p>
          <br />
          <p>
            <strong>9.1.</strong> Será inutilizada qualquer prova de compra que
            apresente indícios de reprodução ou adulteração com o objetivo de
            burlar as disposições constantes neste regulamento ou, ainda, que
            apresente defeitos ou vícios que impeçam a verificação de sua
            autenticidade ou do direito ao cupom.
          </p>
          <br />
          <p>
            <strong>10.1.</strong> Não serão aceitos os cupons de compra cujas
            transações tenham sido efetuadas fora do período de participação do
            Programa de Recompensas do The Best Açaí.
          </p>
          <br />
          <p>
            <strong>11.1.</strong> Ficam os participantes, cientes, desde já,
            que não poderão utilizar de meios escusos para obter os cupons
            fiscais com QRCode para participar do Programa de Recompensas do The
            Best Açaí e/ou de mecanismos que criem condições de cadastramento
            irregular, desleais, fraudulentos ou que atentem contra os objetivos
            e condições de participação previstas neste regulamento, situações
            essas que quando identificadas, serão consideradas, a qualquer
            momento, como infração aos termos do presente regulamento, ensejando
            o impedimento da participação no Programa, sem prejuízo, ainda, das
            medidas cabíveis a ser(em) promovida(s) em face do infrator.
          </p>
          <br />
          <p>
            <strong>12.1.</strong> Os pontos acumulados no cadastro de um
            participante não poderão ser repassados para outro consumidor, seja
            parente, cônjuge ou amigo.
          </p>
          <br />
          <p>
            <strong>13.1.</strong> Ao término do período de participação do
            Programa de Recompensas do The Best Açaí, os pontos de todos os
            participantes serão zerados, não havendo mais possibilidade de
            resgate de nenhum prêmio.
          </p>
          <br />
          <p>
            <strong>14.1. </strong>Há um limite de 200 (duzentos) pontos por CPF
            para o presente Programa. O direito ao recebimento do ponto está
            condicionado à realização de cada avaliação. Caso o(a) participante
            atinja o limite máximo do Programa, não será possível acumular mais
            pontos.
          </p>
          <br />
          <p>
            <strong>14.2.</strong> A quantidade máxima de pontos acumuláveis por
            CPF e a quantidade máxima de cada item de recompensa podem ser
            alteradas sem aviso prévio.
          </p>
          <br />
          <Subtitle>RECOMPENSAS</Subtitle>
          <br />
          <p>
            <strong>15.1.</strong> Conforme a totalidade de pontos indicada na
            descrição de cada item dentro do aplicativo “Clube The Best”, as
            recompensas deste Programa que poderão ser resgatadas com a troca
            dos respectivos pontos acumulados são:
          </p>
          <ol type="i">
            <li>1 (um) Chaveiro personalizado com a logo do The Best Açaí;</li>
            <li>1 (um) Copo personalizado com a arte da coleção Arte Carão;</li>
            <li>
              1 (um) Copo 300 ml personalizado com direito a 2 sabores de
              açaí/sorvete e 3 acompanhamentos;
            </li>
            <li>
              2 (dois) Copos 300 ml personalizados com direito a 2 sabores de
              açaí/sorvete e 3 acompanhamentos cada
            </li>
            <li>1 (uma) Garrafinha personalizada do The Best Açaí;</li>
          </ol>
          <br />
          <p>
            <strong>15.2.</strong> Caso o(a) Participante troque seus pontos
            pelos itens (iii) ou (iv) e decida por acrescentar algum item
            adicional, seja açaí/sorvete ou acompanhamentos extras, o referido
            item será cobrado separadamente.
          </p>
          <br />
          <p>
            <strong>15.3.</strong> Caso o(a) Participante troque seus pontos por
            uma recompensa e não a retire até a data estipulada na descrição da
            recompensa, não será mais possível fazer o resgate da recompensa e
            os pontos serão perdidos, sem qualquer direito a indenização ou
            reembolso.
          </p>
          <br />
          <p>
            <strong>15.4.</strong> Caso o(a) Participante escolha uma recompensa
            para trocar pelos seus pontos e aceite os termos expostos no
            aplicativo “Clube The Best”, não será mais possível desfazer a ação.
            O(A) Participante terá o direito somente de retirar a recompensa já
            escolhida, até o prazo estipulado na descrição da recompensa, no
            entanto não será possível cancelar a requisição já concluída.
          </p>
          <br />
          <p>
            <strong>15.5.</strong> As recompensas previstas neste Regulamento
            estão sujeitas à disponibilidade de estoque das lojas participantes,
            além da finalização de cada etapa de avaliação.
          </p>
          <br />
          <Subtitle>ENTREGA DA RECOMPENSA</Subtitle>
          <br />
          <p>
            <strong>16.1.</strong> A recompensa pode ser retirada em qualquer
            loja do The Best Açaí da escolha do participante, sujeita à
            disponibilidade de estoque.
          </p>
          <br />
          <p>
            <strong>16.2.</strong> Como condição ao recebimento da recompensa,
            o(a) Participante deverá apresentar o seu cadastro no aplicativo
            “Clube The Best” e demonstrar que houve a troca de seus pontos pela
            recompensa escolhida.
          </p>
          <br />
          <p>
            <strong>16.3.</strong> A recompensa será retirada pessoalmente
            pelo(a) próprio(a) Participante, que deverá apresentar a
            documentação necessária à sua inequívoca identificação.
          </p>
          <br />
          <p>
            <strong>16.4.</strong> A recompensa escolhida é pessoal e
            intransferível, não sendo admitida sua negociação, cessão,
            comercialização ou substituição por outra espécie de bens ou
            serviços, nem sua conversão em dinheiro, em nenhuma hipótese.
          </p>
          <br />
          <p>
            <strong>16.5.</strong> A responsabilidade do The Best Açaí cessará
            com a entrega da recompensa ao(a) Participante. Qualquer reclamação
            relativa às especificações, qualidade e garantia das recompensas
            concedidas deverão ser realizadas perante a empresa fabricante do
            produto.
          </p>
          <br />
          <Subtitle>PRESCRIÇÃO</Subtitle>
          <br />
          <p>
            <strong>17.1.</strong> A recompensa deverá ser reclamada dentro do
            prazo de duração do Programa. Após, esse prazo caducará o direito
            do(a) Participante.
          </p>
          <br />
          <Subtitle>DISPOSIÇÕES GERAIS</Subtitle>
          <br />
          <p>
            <strong>18.1. </strong>Exibição das recompensas: as imagens das
            recompensas serão expostas nas dependências das lojas do The Best
            Açaí, bem como por meios de diversos canais de divulgação do
            Programa, sendo todas meramente ilustrativas.
          </p>
          <br />
          <p>
            <strong>19.1.</strong> O cadastramento no Programa implica na cessão
            da imagem do(a) Participante, para fins de divulgação daquela, sem
            quaisquer ônus ao The Best Açaí, ficando totalmente livre para usar
            as referidas imagens em quaisquer peças publicitárias, desde já
            autorizada a sua veiculação em jornal, rádio, TV, outdoor, internet,
            redes sociais ou qualquer outro veículo de comunicação ou mídia,
            pelo período de um ano contado da data da apuração.
          </p>
          <br />
          <p>
            <strong>20.1.</strong> A participação no Programa de Recompensas do
            The Best Açaí caracteriza a aceitação e o reconhecimento integral
            dos termos e condições do presente regulamento. Em relação à
            operação de tratamento de dados pessoais, e em respeito às regras da
            Lei Geral de Proteção de Dados, o The Best Açaí recomenda a leitura
            na íntegra do Aviso de Privacidade.
          </p>
          <br />
          <p>
            <strong>21.1.</strong> O regulamento do Programa de Recompensas do
            The Best Açaí estará disponível nas lojas participantes. Todas as
            lojas do The Best Açaí, sejam elas próprias ou franqueadas,
            participação do presente Programa de Recompensas.
          </p>
          <br />
          <p>
            <strong>22.1.</strong> O regulamento do Programa de Recompensas do
            The Best Açaí poderá ser revisto e alterado sem necessidade de
            qualquer aviso prévio. Para tanto, orientamos que o regulamento seja
            periodicamente consultado, seja no site institucional do The Best
            Açaí ou no aplicativo The Best Club.
          </p>
          <br />
          <p>
            <strong>22.2.</strong> Todos os comunicados, avisos, campanhas,
            regras e diretrizes contidas no aplicativo The Best Club deverão ser
            lidos conjuntamente com as disposições deste regulamento. Em caso de
            divergência entre as disposições deste regulamento e das contidas no
            aplicativo The Best Club, prevalecerão as últimas.
          </p>
          <br />
          <p>
            Fica, desde já, eleito o Foro da Comarca de Londrina, estado do
            Paraná, para a solução de quaisquer questões referentes ao
            regulamento da promoção autorizada.
          </p>
          <br />
          <div className="franchising">
            <h2>GRUPO THE BEST FRANCHISING LTDA</h2>
            <p>28.967.949/0001-84</p>
          </div>
          <div className="update">
            <p>Última atualização: 20 de maio de 2023.</p>
          </div>
        </ContentText>
      </Container>
      <Footer />
    </>
  );
};
