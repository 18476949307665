import styled, { css } from "styled-components";
import { ContainerWidth } from "../../styles/globalStyles";
import { RxMagnifyingGlass } from "react-icons/rx";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .modalContainer {
    width: 45%;
    height: auto;
    min-width: 20rem;
    @media screen and (max-width: 1200px) {
      width: 60%;
    }
    @media screen and (max-width: 860px) {
      width: 80%;
    }
    @media screen and (max-width: 575px) {
      width: 97%;
    }
  }
  .modalbody {
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

interface IBackground {
  background: string;
}

export const CardsContainer = styled(ContainerWidth) <IBackground>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: ${({ background }) => background};

  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
  .clubTheBestTitle {
    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }

  ${ContainerWidth}
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6rem;
  font-family: "Visby-bold";
  font-weight: 900;
`;

export const SpanContent = styled.span`
  text-transform: capitalize;
  text-align: center;
  font-size: 1.4rem;
  color: var(--white);
  font-family: "Visby-regular" !important;

  font-weight: 500;

  @media screen and (max-width: 768px){
      text-align: center;
      font-size: 1.3rem;
    }
`;

export const TitleContent = styled.span`
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  color: var(--primary);
  font-family: "Visby-regular";

  font-weight: 600;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--black);
  font-family: "Visby-bold" !important;

  span {
    color: var(--white);
  }
  @media screen and (max-width: 1000px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 700px) {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 2rem;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;

  width: 100%;
  margin-top: 2rem;
  height: fit-content;

  @media screen and (max-width: 575px) {
    grid-auto-flow: row;
    grid-template-rows: auto;
  }
`;
export const ImgContainer = styled.div`
  display: grid;
  grid-auto-flow: column;

  grid-template-columns: 30% 5% 30% 5% 30%;
  width: 100%;
  margin: 1.5rem 0;

  @media screen and (max-width: 575px) {
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-template-rows: auto;
  }
`;

export const IconsCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  margin: 0 2%;
`;

export const Icons = styled.img`
  width: 11rem;

  @media screen and (max-width: 1650px) {
    width: 7rem;
  }
  @media screen and (max-width: 800px) {
    width: 5rem;
  }

  @media screen and (max-width: 575px) {
    width: 13rem;

    margin-top: 1rem;
  }
`;

export const IconTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1.7rem;

  color: var(--black);
  font-family: "Visby-regular";
  font-size: 2rem;
  font-weight: 600;

  @media screen and (max-width: 1500px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 1.4rem;
  }
`;

export const IconText = styled.div`
  text-align: center;
  font-family: "Visby-regular";
  font-weight: 600;
  font-size: 1.1rem;

  margin-top: 0.4rem;
  padding: 0 2%;

  .strong {
    font-weight: 900;
  }
  @media screen and (max-width: 575px) {
    font-size: 0.9rem;
  }
`;

export const Imgs = styled.img`
  width: 15rem;
  height: 15rem;
  @media screen and (max-width: 1650px) {
    width: 12rem;
    height: 12rem;
  }
  @media screen and (max-width: 800px) {
    width: 10rem;
    height: 10rem;
  }
  @media screen and (max-width: 800px) {
    width: 9rem;
    height: 9rem;
  }
  @media screen and (max-width: 575px) {
    width: 15rem;
    height: 15rem;
    margin-top: 1rem;
  }
`;
export const ImgsArrow = styled.img`
  width: 7rem;
  height: 7rem;
  @media screen and (max-width: 1650px) {
    width: 4.5rem;
    height: 4.5rem;
  }
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const ImgTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.4rem;

  color: var(--black);
  font-family: "Visby-regular";
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
`;

export const ImgText = styled.div`
  text-align: center;
  font-family: "Visby-regular";
  font-weight: 600;
  font-size: 1.1rem;

  margin-top: 0.4rem;
  padding: 0 2%;

  .strong {
    font-weight: 900;
  }
`;

export const IconArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60%;
`;

export const AboutAppContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 3rem 0 1.5rem;
  background: var(--white-100);
  @media screen and (max-width: 575px) {
    margin: 5rem 0 1.5rem;
  }
`;

export const AboutApp = styled(ContainerWidth)`
  display: grid;

  grid-auto-flow: column;
  grid-template-columns: 40% 60%;

  padding: 3rem;

  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0rem;

    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
`;

export const Iphone = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    padding: 3rem;
  }
`;

export const TitleApp = styled.h2`
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  color: var(--black);
  font-family: "Visby-demi-bold";

  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;

  @media screen and (max-width: 1500px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 575px) {
    margin-top: 2rem;
  }
`;

export const ContentAppAbout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
  p {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.2rem;

    @media screen and (max-width: 800px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
`;

export const Button = styled.img`
  width: 80%;
  max-width: 14rem;
  max-height: 5.3rem;
  border-radius: 0.2rem;
  margin-right: 0.4rem;
  transition: all 0.2s ease-in-out;
  @media screen and (max-width: 575px) {
    margin: 0;
  }

  :hover {
    cursor: pointer;

    transform: scale(1);
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 2% 0;
  a {
    transition: all 0.3s ease-in-out;

    :hover {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
  }
`;

export const ContentItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 40%;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 1rem;
      }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentPrize = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 2rem;
    width: 70%;

    @media screen and (max-width: 768px){
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
    }
  }
`;

export const ButtonClub = styled.button`
  display: flex;
  width: 23rem;
  height: 4.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5rem 1rem;
  border: none;
  cursor: pointer;
  margin-top: 2rem;

  font-size: 1.5rem;
  font-weight: bold;
  color: var(--white);
  background-color: var(--primary);
  border-radius: 30px;

  :hover {
    opacity: 70%;
  }
`;

export const QuestionsContainer = styled(ContainerWidth)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  ${ContainerWidth}
`;

export const Text = styled.span`
    font-size: 1.5rem;
    width: 70%;

    @media screen and (max-width: 768px){
      width: 100%;
      text-align: center;
      font-size: 1.3rem;
    }
`;
interface IProps {
  active: boolean;
}

export const QuestionsCards = styled.div<IProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 3%;

  margin-bottom: 1rem;

  border-radius: 1rem;
  box-shadow: 1px 1px 3px 3px rgba(8, 15, 52, 0.06);

  transition: border 0.5s ease;

  ${({ active }) => {
    if (active) {
      return css`
        border: 2px solid var(--primary);
      `;
    }
  }}

  :hover {
    cursor: pointer;
  }
  :last-child {
    margin-bottom: 0;
  }
`;
export const QuestionsTitleContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 95% 5%;
  width: 100%;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 93% 7%;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 85% 15%;
  }
`;
export const QuestionsTitle = styled.h1`
  margin: 0;
  padding: 0.1rem;

  font-family: "Visby-regular";
  font-weight: 500;
  font-size: 1.5rem;

  color: var(--black-20);
  @media screen and (max-width: 1050px) {
    font-size: 1.1rem;
  }
`;

export const QuestionsText = styled.p<IProps>`
  width: 90%;
  height: 6rem;

  margin-top: 1rem;

  font-family: "Visby-regular";
  font-weight: 400;
  font-size: 1.1rem;

  overflow: hidden;

  transition: height 0.5s, opacity 2s;

  ${({ active }) => {
    if (!active) {
      return css`
        transition: height 1.3s, opacity 0.5s;
        height: 0;
        opacity: 0;
      `;
    }
  }};
  @media screen and (max-width: 1050px) {
    font-size: 0.9rem;
    height: 7rem;
    ${({ active }) => {
    if (!active) {
      return css`
          height: 0;
        `;
    }
  }};
  }
  @media screen and (max-width: 840px) {
    height: 10rem;
    ${({ active }) => {
    if (!active) {
      return css`
          height: 0;
        `;
    }
  }};
  }
  @media screen and (max-width: 575px) {
    height: 15rem;
    ${({ active }) => {
    if (!active) {
      return css`
          height: 0;
        `;
    }
  }};
  }
`;

export const QuestionIcon = styled.img`
  width: 20px;
  height: 20px;
  @media screen and (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
`;

export const QuestionIconContent = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px 3px rgba(8, 15, 52, 0.06);
  transition: background-color 1s;
  ${({ active }) => {
    if (active) {
      return css`
        background-color: orange;
      `;
    }
  }}
  @media screen and (max-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }
  @media screen and (max-width: 500px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const Icon = styled(RxMagnifyingGlass)`
  position: absolute;
  color: #f9a003;
  display: flex;
  left: 80%;
  width: 1.5rem;
  height: 2rem;

  cursor: pointer;

  @media screen and (max-width: 1024px) {
    left: 65%;
    top: 5%;
  }

  @media screen and (max-width: 768px) {
    left: 70%;
  }

  @media screen and (max-width: 425px) {
    left: 89%;
    top: 5%;
  }
`;

export const InputFind = styled.input`
  height: 2.5rem;
  border: 1px solid #f8aa00;
  padding: 0 2rem;
  border-radius: 5px;
  font-size: 1.1rem;

  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0 .5rem;
  }

  &::placeholder {
    color: var(--black);
    opacity: 30%;
    font-size: 1rem;
    font-family: "Visby-regular";
  }
`;
