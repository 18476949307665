import styled from "styled-components";

export const TitleModal = styled.h1`
  margin: 0;
  width: 35rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Visby-bold";
  strong {
    color: var(--primary);
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 860px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 1rem;
  }
`;
export const ModalBodyContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  @media screen and (max-width: 575px) {
    align-items: center;
  }
`;
export const ModalBodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  .modalAppleButton {
    min-width: 10rem;
    margin: 2% 0;
    @media screen and (max-width: 575px) {
      min-width: 8rem;
    }
  }
  .modalAndroidButton {
    min-width: 10rem;
    margin: 2% 0;
    @media screen and (max-width: 575px) {
      min-width: 8rem;
    }
  }
`;
export const ModalBodyText = styled.p`
  font-size: 1rem;
  letter-spacing: 0.1rem;
`;

export const ModalBodyImg = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`;
export const Button = styled.img`
  width: 48%;
  max-width: 14rem;
  max-height: 5.3rem;
  border-radius: 0.2rem;
  margin-right: 0.4rem;
  @media screen and (max-width: 575px) {
    margin: 0;
  }

  :hover {
    cursor: pointer;
  }
`;
