import React from "react";
import { Back, Card, Front, MainContainer } from "./styles";

interface IBoxProps {
  img?: any;
  alt?: string;
  text: React.ReactNode;
  back: React.ReactNode;
  title: React.ReactNode;
}

export const Box: React.FC<IBoxProps> = ({ img, text, alt, back, title }) => {
  return (
    <>
      <MainContainer>
        <Card className="card">
          <Front>
            <img src={img} alt={alt} />
            <p>{text}</p>
          </Front>
          <Back>
            <h3>{title}</h3>
            <p>{back}</p>
          </Back>
        </Card>
      </MainContainer>
    </>
  );
};
