import React from "react";
import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import personAvatar from "../../assets/Icons/personAvatar.svg";
import Carousel from "react-elastic-carousel";

import {
  BottomImage,
  Boxes,
  ButtonGupy,
  Container,
  ContentCulture,
  ContentSlogan,
  Slogan,
  Subtitle,
  TextSlogan,
} from "./styles";

import { Footer } from "../../components/Footer";
import { Box } from "../../components/Box";
import { Helmet } from "react-helmet-async";

export const TrabalheConosco = () => {
  const BannerImg = require("../../assets/Images/Banners_site/banner-trabalhe-conosco.webp");
  const ImgMobileTrabalheConosco = require("../../assets/Images/Banners-mobile/banner-trabalhe-conosco.webp");
  const BottomImg = require("../../assets/Images/image_trabalhe_conosco.webp");
  const Lealdade = require("../../assets/Images/Icones_trabalhe_conosco/Lealdade.webp");
  const Comprometimento = require("../../assets/Images/Icones_trabalhe_conosco/Comprometimento.webp");
  const Humildade = require("../../assets/Images/Icones_trabalhe_conosco/Humildade.webp");

  const items = [
    {
      img: Lealdade,
      alt: "ícone de um indivíduo subindo no pódio",
      text: "Lealdade",
      title: "Lealdade",
      back: "A lealdade é o nosso principal pilar entre nosso time e nossos parceiros para crescermos juntos!",
      key: 1,
    },
    {
      img: Comprometimento,
      alt: "ícone de um pódio",
      text: "Comprometimento",
      title: "Comprometimento",
      back: "O comprometimento é o que faz estarmos de corpo e alma pelas pessoas e pelo negócio, é o que amamos fazer!",
      key: 2,
    },
    {
      img: Humildade,
      alt: "ícone do coletivismo que contém três indivíduos desenhados",
      text: "Humildade",
      title: "Humildade",
      back: "Abrir-se para ouvir e aprender, sempre que necessário, nos conduz a um crescimento constante e fortalece nossas conexões!",
      key: 2,
    },
  ];

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Trabalhe conosco</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/trabalhe-conosco`}
        />
        <meta property="og:image" content={BannerImg} />
        <meta name="twitter:image" content={BannerImg} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <BannerHome
        img={BannerImg}
        alt="Banner da página Trabalhe conosco. Na foto, imagens de trabalhadores da rede The Best"
        text={false}
        imgMobile={ImgMobileTrabalheConosco}
      />
      <Container>
        <h1>
          Quer <span>trabalhar </span>conosco?
        </h1>
        <h3>Então entenda como funciona o Grupo The Best</h3>

        <ContentCulture>
          <h2>
            Nossos <span>valores</span>
          </h2>
        </ContentCulture>

        {/* <Boxes>
          <Box
            img={Coletivismo}
            text="Coletivismo"
            alt="ícone do coletivismo que contém três indivíduos desenhados"
          />
          <Box img={Disciplina} text="Disciplina" alt="ícone de um pódio" />
          <Box
            img={Atitude}
            text="Atitude"
            alt="ícone de um indivíduo subindo no pódio"
          />
        </Boxes> */}
        <Boxes>
          {/* @ts-ignore */}
          <Carousel
            showArrows={false}
            enableAutoPlay={false}
            itemsToShow={window.innerWidth > 700 ? 3 : 1}
            enableSwipe={window.innerWidth > 700 ? false : true}
            outerSpacing={50}
          >
            {items.map((box) => (
              <Box
                img={box.img ? box.img : personAvatar}
                alt={box.alt}
                text={box.text}
                back={box.back}
                title={box.title}
              />
            ))}
          </Carousel>
        </Boxes>
        <ContentSlogan>
          <Slogan>
            Faça o seu <span>melhor</span>
            <b>.</b>
          </Slogan>

          <Subtitle>Esse é o nosso lema.</Subtitle>

          <TextSlogan>
            Acreditamos profundamente em nossos valores, tendo em mente a
            importância de nossa própria cultura. A empresa busca a diversidade,
            inclusão, agilidade e excelência. Nosso ambiente é múltiplo e
            respeitamos as pessoas como elas são, promovendo a importância do
            trabalho em equipe e ajudando a construir cada degrau dessa jornada.
          </TextSlogan>

          <ButtonGupy
            target="_blank"
            rel="noreferrer"
            href="https://app.zazos.com/t/grupo-the-best/p/2123b6ca-94ee-4ecd-bae1-816eadd386ab"
          >
            Veja nossas vagas
          </ButtonGupy>
        </ContentSlogan>
      </Container>
      <BottomImage
        src={BottomImg}
        alt="Imagem de uma franquia The Best Açaí "
      />
      <Footer />
    </>
  );
};
