import styled from "styled-components";
import { ContainerHome } from "../../styles/globalStyles";
import { RxMagnifyingGlass } from "react-icons/rx";

import { Instagram } from "../../styles/icons";
import { ContainerImg } from "../../components/Banners/styles";

const BannerImg = require("../../assets/Images/Banner_Sympla/Banner-sympla.png");

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .modalContainer {
    display: flex;
    justify-content: space-between;
    width: 45%;
    height: auto;
    max-height: 45rem;
    background: var(--gray-10);

    @media screen and (max-width: 500px) {
      max-height: 35rem;
    }
    .modalHeader {
      padding: 0.5rem;
      .modalTitle {
        font-family: "Visby-bold";
        font-size: 1.5rem;
        @media screen and (max-width: 350px) {
          font-size: 1.6rem;
        }
      }
    }
    .modalBody {
      overflow-y: auto;
      background: var(--white);
      p {
        letter-spacing: 0;
        font-size: 1.2rem;
        @media screen and (max-width: 350px) {
          font-size: 0.7rem;
        }
      }
    }

    
  }
  .modalFooter {
    padding: 1rem 0 0 0;
    img {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
    span {
      font-family: "Visby-bold";
      font-size: 1.1rem;
    }
  }
`;


export const BackgroundContainer = styled(ContainerImg)`
  background-image: url("${BannerImg}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 490px;
  position: absolute;
  z-index: 0;
  filter: blur(8px) brightness(0.9);

  @media screen and (max-width: 850px) {
    background-image: none;
    min-height: 0;
  }
  @media screen and (max-width: 575px) {
    background-image: url("${BannerImg}");
    min-height: 490px;
  }
  @media screen and (max-width: 490px) {
    min-height: 0;
  }
`;

export const ContainerBanner = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-height: 490px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  transition: ease-in-out 200ms;
`;

export const ContainerSearch = styled(ContainerHome)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
  ${ContainerHome}
`;

export const FindStore = styled.div`
  padding: 3.125rem 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FindStoreTitle = styled.h2`
  font-size: 2.8rem;
  text-align: center;
  font-family: "Visby-regular";
  span {
    font-family: "Visby-bold";
    display: contents;
    text-align: center;
    color: #f8aa00;
  }

  @media screen and (max-width: 1700px) {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const FindStoreSubtitle = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0 4.5rem;
  font-family: "Visby-regular";
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 1rem 0 2.2rem;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  width: 83%;
  height: 4.5rem;
  justify-content: space-between;
  position: relative;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const InputFind = styled.input`
  border: 1px solid #f8aa00;
  width: 67%;
  height: 100%;
  padding: 0 40px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;

  &::placeholder {
    color: var(--black);
    opacity: 30%;
    font-size: 1.5rem;
    font-family: "Visby-regular";

    @media screen and (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 3rem;
    padding: 0 20px;
  }
`;

export const ButtonLocation = styled.button`
  width: 30%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #f8aa00;
  border-radius: 5px;
  background-color: transparent;
  font-size: 1.6rem;
  color: #f8aa00;
  cursor: pointer;

  &:hover {
    color: var(--black);
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 1400px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 3rem;
    margin-top: 1.2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const ContentAbout = styled(ContainerHome)`
  max-width: 100%;
  overflow: hidden;
  ${ContainerHome}
`;
export const About = styled.div`
  display: flex;
`;
export const Title = styled.h1`
  font-family: "Visby-regular";
  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1.2rem;
    font-size: 2rem;
  }
  @media screen and (max-width: 500px) {
    text-align: left;
    font-size: 1.4rem;
  }
`;

export const Detail = styled.img`
  width: 4rem;
  height: 5rem;
  margin-bottom: 1rem;
  object-fit: contain;
  @media screen and (max-width: 1200px) {
    width: 3rem;
    height: 4rem;
  }
`;

export const ContentGeral = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentStickers = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1300px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ContentStickerLeft = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.5rem;
    width: 38rem;
    letter-spacing: 0;

    @media screen and (max-width: 1600px) {
      width: 33rem;
      font-size: 1.3rem;
    }
    @media screen and (max-width: 1400px) {
      font-size: 1.1rem;
      width: 30rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      text-align: left;
    }
  }
`;

export const Stickers = styled.img`
  width: 36rem;
  height: 29rem;
  margin-top: -5rem;
  object-fit: contain;

  @media screen and (max-width: 1700px) {
    width: 28rem;
    height: 25rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: 1500px) {
    width: 25rem;
    height: 20rem;
  }
  @media screen and (max-width: 1500px) {
    width: 22rem;
    height: 22rem;
  }
  @media screen and (max-width: 1024px) {
    margin-top: -3rem;
    z-index: -1;
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const AboutApp = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding-top: 8rem;
  background: linear-gradient(white 82%, #f8aa00 82% 84%, white 84%);

  margin: 0 -16.25rem;
  padding: 3rem 16.25rem;

  @media screen and (max-width: 1500px) {
    margin: 0 -13rem;
    padding: 3rem 13rem;
  }
  @media screen and (max-width: 1300px) {
    margin: 0 -10rem;
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    margin: 0 -8rem;
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    margin: 0 -4rem;
    padding: 3rem 4rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 -2rem;
    padding: 3rem 2rem;
  }

  @media screen and (max-width: 1100px) {
    padding-top: 4rem;
    display: flex;
    align-items: flex-end;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 1rem;
  }
`;

export const Iphone = styled.img`
  margin-left: -5.125rem;
  width: 40rem;

  @media screen and (max-width: 1500px) {
    width: 30rem;
  }
  @media screen and (max-width: 1024px) {
    width: 23rem;
  }
  @media screen and (max-width: 800px) {
    width: 17rem;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const TitleApp = styled.img`
  width: 95%;
  margin: 6.5rem 0 1.5rem;

  @media screen and (max-width: 575px) {
    margin: 3rem 0 1.5rem;
  }
`;

export const ContentAppAbout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    width: 100%;
    letter-spacing: 0;

    @media screen and (max-width: 1500px) {
      font-size: 1.2rem;
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
  img {
    margin-top: 0rem;
  }
`;

export const ContentGalery = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  h3 {
    margin-bottom: 2.5rem;
  }
  @media screen and (max-width: 1100px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;
export const ImagemContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    :last-child {
      display: none;
    }
  }
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 23px;
  grid-row-gap: 23px;
  margin-bottom: 2rem;

  .instaIcon {
    position: absolute;
    width: 4rem;
    height: 4rem;
    flex-direction: center;
    cursor: pointer;
    z-index: 1;

    :hover + .instaImage {
      filter: brightness(0.3);
      z-index: 0;
    }
  }

  .instaImage {
    width: 25rem;
    height: 25rem;
    border-radius: 5px;
    object-fit: cover;
    transition: 500ms;
    cursor: pointer;
    z-index: 2;

    :hover {
      z-index: 0;
      transition: 400ms;
      filter: brightness(0.3);
    }

    @media screen and (max-width: 1700px) {
      width: 20rem;
      height: 20rem;
    }
    @media screen and (max-width: 1400px) {
      width: 15rem;
      height: 15rem;
    }
    @media screen and (max-width: 600px) {
      width: 10rem;
      height: 10rem;
    }
    @media screen and (max-width: 400px) {
      width: 8rem;
      height: 8rem;
    }
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const ImgInstagram = styled(Instagram)`
  margin-left: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
`;

export const InstagramButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9a003;
  padding: 1rem;
  border-radius: 8px;
  border: 0;
  font-family: "Visby-regular";
  font-size: 1rem;
  color: var(--white);
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  cursor: pointer;

  :hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Icon = styled(RxMagnifyingGlass)`
  position: absolute;
  color: #f9a003;
  display: flex;
  left: 60%;

  @media screen and (max-width: 1024px) {
    left: 85%;
    top: 5%;
  }
`;

export const ContentSwiper = styled(ContainerHome)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ContainerHome}
`;

export const ContainerSwiper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  h3 {
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;

export const ContentTestimonial = styled.div`
  width: 100%;
  .body {
    display: inline-block;
    flex-direction: column;
  }
  .moreTestimonial {
    font-weight: bold;
    color: var(--primary);

    :hover {
      color: #f9a940;
      cursor: pointer;
    }
  }
`;

export const ContentNewPapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
  h3 {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 1024px) {
    h3 {
      margin-bottom: 0rem;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    h3 {
      font-size: 1.4rem;
    }
  }
`;

export const Materias = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  align-items: center;

  cursor: pointer;

  .btg,
  .istoEDinheiro {
    filter: grayscale(10);
  }
  img {
    width: 15rem;
    transition: 0.2s ease-in;
    :hover {
      opacity: 30%;
    }

    :nth-last-child() {
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 1024px) {
      width: 10rem;
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 0;
  }
`;

export const BannerSelo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  @media screen and (max-width: 1100px) {
    padding: 0 2rem;
  }
`;

export const SeloDesktop = styled.img`
  object-fit: contain;
  margin-top: 2.5rem;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const SeloMobile = styled.img`
  display: none;
  @media screen and (max-width: 1100px) {
    display: revert;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
