import styled, { css } from "styled-components";
interface IProps {
  text?: boolean;
  img?: string;
  imgMobile?: string;
}
export const ContainerImg = styled.div<IProps>`
  display: flex;
  justify-content: center;
  width: 100%;

  .textPrimary {
    color: var(--yellow-20);
    display: contents;
  }

  .theBestApp {
    color: var(--black);
  }

  ${({ text, img }) => {
    if (text) {
      return css`
        background: url("${img}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        height: 80vh;
        min-height: 35rem;
        justify-content: flex-start;
      `;
    }
  }}
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  object-fit: contain;
  overflow: hidden;

  @media screen and (max-width: 575px) {
    object-fit: cover;
    display: none;
  }
`;

export const ImgMobile = styled.img`
  padding-right: 0;
  border: 0;
  overflow: hidden;
  display: none;
  @media screen and (max-width: 575px) {
    display: revert;
  }
`;

export const ContentText = styled.div`
  padding-top: 8.5%;
  padding-left: 5%;
  width: 35%;
  h1 {
    margin: 0;
    color: var(--white);
    text-transform: uppercase;
    font-family: "Visby-bold";

    @media screen and (max-width: 500px) {
      height: 36%;
    }
  }
  @media screen and (max-width: 1350px) {
    width: 45%;
  }
  @media screen and (max-width: 1000px) {
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    width: 55%;
  }
  @media screen and (max-width: 575px) {
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
  }
`;

export const Text = styled.h1`
  display: flex;
  text-align: justify;
`;

export const ButtonBanner = styled.button`
  background-color: var(--yellow-20);
  color: var(--white);
  width: 85%;
  height: 5.8rem;

  border-radius: 20px;
  cursor: pointer;
  border: 0;

  font-size: 1.8rem;
  font-family: "Visby-bold";

  :hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1370px) {
    width: 90%;
  }
  @media screen and (max-width: 960px) {
    width: 80%;
  }
  @media screen and (max-width: 700px) {
    width: 90%;
  }
  @media screen and (max-width: 500px) {
    width: 60%;
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 350px) {
    margin-top: 6.5rem;
  }
`;
