import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { IInputProp } from "../../model/posts";

import { AuthContainer, BoxButton, Container, FormAuth, Img } from "./styles";

import apiJanus from "../../services/apiJanus";
import {
  setTokenOnStorage,
  setPermissinosOnStorage,
  removeTokenOnStorage,
} from "../../services/user";
import { Spinner } from "../../components/Spinner";
import Swal from "sweetalert2";

export const Auth: React.FC<IInputProp> = () => {
  const navigate = useNavigate();

  const ImgCoroa = require("../../assets/Images/coroa_home.webp");
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    username: yup
      .string()
      .required("O username é obrigatório")
      .email("Formato de username inválido"),
    password: yup
      .string()
      .min(6, "A senha precisa de no mínimo de 6 caracteres"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function handleSignIn(payload: any) {
    setLoading(true);
    await removeTokenOnStorage();
    try {
      const { data } = await apiJanus.post("/user/login", payload);
      if (data) {
        const { access_token, permissions } = data;
        if (access_token && permissions) {
          setTokenOnStorage(access_token);
          setPermissinosOnStorage(permissions);
          Swal.fire({
            icon: "success",
            title: "Login feito com sucesso!",
            text: "Bem-vindo",
          }).then((result) => {
            if (result.isConfirmed) navigate("/");
          });
        }
      }
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Verifique se os dados estão digitados corretamente",
      });
      setLoading(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <AuthContainer>
          <Img src={ImgCoroa} />
          <h1>Login</h1>
          <FormAuth>
            <label htmlFor="email">Usuário/e-mail</label>

            <input
              type="username"
              placeholder="Digite aqui seu usuário"
              {...register("username")}
            />

            {errors.username && <span>{errors.username.message}</span>}
            <label htmlFor="password">Senha</label>
            <input
              type="password"
              placeholder="*******"
              {...register("password")}
            />
            <span>{errors?.password?.message}</span>

            {loading ? (
              <Spinner />
            ) : (
              <BoxButton>
                <button onClick={handleSubmit(handleSignIn)}>Login</button>
              </BoxButton>
            )}
          </FormAuth>
        </AuthContainer>
      </Container>
    </>
  );
};
