import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 16.25rem 0;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1500px) {
    padding: 3rem 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 3rem;
  }
  @media screen and (max-width: 575px) {
    padding: 1.5rem 2rem 1rem;
  }
  @media screen and (max-width: 420px) {
    padding: 1.5rem 1rem 1rem;
  }

  h1 {
    font-size: 3rem;

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      align-items: flex-start;
      margin-bottom: 0;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.4rem;
    }
  }

  h3 {
    color: #a7a7a7;
    font-family: "Visby-light";
    font-size: 1.5rem;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      margin-top: 1rem;
      width: 80%;
      text-align: center;
    }
  }

  span {
    font-family: "Visby-bold";
  }
`;

export const ContentCulture = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;

  h2 {
    font-family: "Visby-light";
    font-size: 4rem;

    @media screen and (max-width: 768px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.4rem;
    }
  }

  span {
    font-family: "Visby-demi-bold";
    color: #f8aa00;
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
  }
`;

export const Boxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 2.5rem;
  .rec-dot {
    margin: 0 0.5rem;
  }
  .rec .rec-dot {
    display: none;
  }
  .rec-slider-container{
    margin:0 0.6rem 0 2rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 0;
  }

  @media screen and (max-width: 700px) {
    .rec .rec-dot {
      display: flex;
    }
  }
`;

export const InnerBox = styled.img`
  width: 13rem;
  height: 13rem;
`;

export const ContentSlogan = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 500px) {
    align-items: flex-start;
  }
`;

export const Slogan = styled.h4`
  font-size: 4rem;
  font-family: "Visby-regular";
  letter-spacing: 0;

  span {
    color: #f8aa00;
    text-transform: uppercase;
    font-family: "Visby-demi-bold";
  }
  b {
    font-size: 4rem;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const Subtitle = styled.p`
  font-family: "Visby-bold";
  font-size: 1.5rem;
  margin: 2rem 0 2.5rem 0;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
`;

export const TextSlogan = styled.article`
  font-size: 1.25rem;
  font-family: "Visby-regular";
  text-align: center;
  line-height: 2rem;

  @media screen and (max-width: 768px) {
    margin: 0;
    text-align: left;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

export const BottomImage = styled.img`
  width: 100%;
  margin-top: 6rem;
  margin-bottom: -0.7rem;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const ButtonGupy = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8aa00;
  color: var(--white);
  border: 0;
  border-radius: 5px;
  padding: 1.3rem 2rem 1rem;
  margin-top: 3rem;
  font-family: "Visby-bold";
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  :hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;
