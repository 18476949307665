import React from "react";
import {
  AmadelliCrownIcon,
  BarBottom,
  BarTop,
  CircleBottom,
  CircleTop,
  Container,
  Divider,
  IndustryRoundedIcon,
  LineBarBottom,
  LineBarTop,
  LineBottom,
  LineTop,
  ShopIcon,
  TextContainer,
  TextContent,
  Title,
  TriangleBottom,
  TriangleTop,
  TrophyIcon,
} from "./styles";

export const TimeLine: React.FC = () => {
  return (
    <Container>
      <Divider>
        <LineTop>
          <TextContainer style={{ justifyContent: "flex-end" }}>
            <Title>PRIMEIRA LOJA</Title>
            <TextContent>
              Os primeiros passos foram dados em Londrina/PR, sem nem imaginar a
              dimensão que a The Best tomaria.
            </TextContent>
          </TextContainer>
          <TriangleTop />
          <LineBarTop className="firstBar">
            <strong>2017</strong>
          </LineBarTop>
          <BarTop />
          <CircleTop>
            <ShopIcon />
          </CircleTop>
        </LineTop>
      </Divider>
      <Divider>
        <LineBottom>
          <CircleBottom>
            <IndustryRoundedIcon />
          </CircleBottom>
          <BarBottom />
          <LineBarBottom>
            <strong>2019</strong>
          </LineBarBottom>
          <TriangleBottom />
          <TextContainer>
            <Title>A FÁBRICA</Title>
            <TextContent>
              A sábia decisão de fabricar os próprios produtos, garantindo a
              qualidade e liberdade de inovação que os clientes merecem.
            </TextContent>
          </TextContainer>
        </LineBottom>
      </Divider>
      <Divider>
        <LineTop>
          <TextContainer style={{ justifyContent: "flex-end" }}>
            <Title>100 LOJAS</Title>
            <TextContent className="lojas">
              Um marco importante que nos mostrou que estávamos no caminho
              certo.
            </TextContent>
          </TextContainer>
          <TriangleTop />
          <LineBarTop>
            <strong>2021</strong>
          </LineBarTop>
          <BarTop />
          <CircleTop>
            <TrophyIcon />
          </CircleTop>
        </LineTop>
      </Divider>
      <Divider>
        <LineBottom>
          <CircleBottom>
            <AmadelliCrownIcon />
          </CircleBottom>
          <BarBottom />
          <LineBarBottom className="lastBar">
            <strong className="moment">ATUALMENTE</strong>
          </LineBarBottom>
          <TriangleBottom />
          <TextContainer>
            <TextContent className="last_text">
              Fazendo nosso melhor, seguimos crescendo em território nacional e
              em produção mensal.
            </TextContent>
          </TextContainer>
        </LineBottom>
      </Divider>
    </Container>
  );
};
