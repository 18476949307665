import axios from "axios";
import { getTokenOnStorage } from "../services/user";

const api = axios.create({
  baseURL: "https://prd-amatech.com/api/s3handler",
  responseType: "json",
});

api.interceptors.request.use((config) => {
  const token = getTokenOnStorage();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
