import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 34rem;
  max-width: 25rem;
  min-height: 34rem;
  border: 1px solid var(--gray-50);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 1rem;

  justify-content: center;
  text-decoration: none;

  transition: all 200ms;
`;

export const Img = styled.img`
  width: 100%;
  height: 17rem;
  object-fit: cover;
`;

export const ContainerContent = styled.div`
  padding: 1rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    padding: 0.5rem 1rem;
  }

  h1 {
    font-size: 1.3rem;
    color: var(--black);
    height: 6.5rem;
    margin: 0;
    line-height: 1.5rem;
    @media screen and (max-width: 1200px) {
      height: 5.5rem;
    }
    @media screen and (max-width: 768px) {
      height: 7.2rem;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
    }
  }
  h2 {
    font-size: 1.1rem;
    color: var(--black);
    margin-top: 1rem;
    height: 5rem;
    line-height: 1.2rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }

  span {
    display: flex;
    margin-top: 1rem;
    font-size: 1rem;
    color: var(--gray-75);
    height: 1rem;
    @media screen and (max-width: 1200px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 0.9rem;
    }
  }
`;
