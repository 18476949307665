import React, { Dispatch, SetStateAction } from "react";

import { CustomModal } from "../Modal";

import {
  Button,
  ModalBodyContainer,
  ModalBodyImg,
  ModalBodyText,
  ModalBodyTextContainer,
  TitleModal,
} from "./styles";

interface IModalProps {
  itemModalOpen: boolean;
  setItemModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalApp: React.FC<IModalProps> = ({
  itemModalOpen,
  setItemModalOpen,
}) => {
  const qrCode = require("../../assets/Images/TheBestApp/qrcode.png");
  const android = require("../../assets/Images/android.webp");
  const apple = require("../../assets/Images/apple.webp");
  const modalTheBestIcon = require("../../assets/Images/modalTheBestIcon.webp");
  return (
    <CustomModal
      title={
        <TitleModal>
          OS MELHORES CUPONS ESTÃO NO <strong>APP Clube The Best</strong>
        </TitleModal>
      }
      isActive={itemModalOpen}
      handleClose={() => setItemModalOpen(false)}
    >
      <ModalBodyContainer>
        <ModalBodyTextContainer>
          <ModalBodyText>Aponte a câmera para a tela e baixe!</ModalBodyText>
          <ModalBodyImg
            src={qrCode}
            style={{ maxWidth: "17rem" }}
            alt="QR Code do download do Clube The Best"
          ></ModalBodyImg>
          <a
            href="https://apps.apple.com/br/app/thebest-club/id6463561926"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              src={apple}
              className={"modalAppleButton"}
              onClick={() => setItemModalOpen(true)}
              alt="Foto da Apple Store"
            ></Button>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.amatech.thebestclubapp"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              src={android}
              className={"modalAndroidButton"}
              onClick={() => setItemModalOpen(true)}
              alt="Foto da Google Play"
            ></Button>
          </a>
        </ModalBodyTextContainer>
        <ModalBodyImg
          src={modalTheBestIcon}
          alt="Foto de dois copos do Clube The Best"
        ></ModalBodyImg>
      </ModalBodyContainer>
    </CustomModal>
  );
};
