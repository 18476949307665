import { datadogRum } from "@datadog/browser-rum";

const clientToken = process.env.REACT_APP_DD_TOKEN as string;

datadogRum.init({
  applicationId: "014570c8-2390-4578-9ea0-9f71b926b06a",
  clientToken,
  site: "us3.datadoghq.com",
  service: "thebest-website",
  env: "prod",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

if (clientToken) datadogRum.startSessionReplayRecording();