import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.125rem 16.25rem;
  flex-direction: column;
  width: 100%;

  .modalContainer {
    height: 20%;
    width: 60%;
  }
  .modalHeader {
    padding: 1rem;
  }
  .modalBody {
    padding: 1rem;
  }

  @media screen and (max-width: 1500px) {
    padding: 3rem 13rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 10rem;
  }
  @media screen and (max-width: 1200px) {
    padding: 3rem 8rem;
  }
  @media screen and (max-width: 900px) {
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem 1rem;
    align-items: center;
  }
  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
`;

export const TopContent = styled.section`
  padding-top: 3.125rem;
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

export const SectionSearch = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  h1 {
    font-size: 3rem;
    font-family: "Visby-regular";
    @media screen and (max-width: 400px) {
      font-size: 1.4rem;
    }
  }

  select {
    width: 100%;
    height: 4.1rem;
    border-radius: 8px;
    border: 1px solid var(--gray-75);
    padding: 1.125rem 1.25rem;
    margin-right: 1.2rem;

    &::placeholder {
      color: var(--black);
      opacity: 30%;
      font-size: 1.5rem;
      font-family: "Visby-regular";
    }

    &::placeholder {
      font-family: "Visby-regular";
    }

    @media screen and (max-width: 500px) {
      height: 3rem;
      padding: 0.5rem;
    }
  }

  option {
    font-family: "Visby-regular";
    font-size: 1rem;
  }
`;

export const Line = styled.hr`
  width: 100%;
  color: var(--gray-75);
  height: 2px;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: column;
`;

export const ContentStores = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 60px;

  @media screen and (max-width: 1400px) {
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(max-content, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    width: 100%;
  }
`;

export const StoreBox = styled.div`
  border: 2px solid var(--gray-50);
  border-radius: 5px;
  height: 31rem;
  width: 35rem;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .rightinnerbox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  h4 {
    font-family: "Visby-regular";
    font-size: 1.3rem;
    color: var(--black);
    height: 4rem;
    @media screen and (max-width: 500px) {
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }

  h6 {
    font-family: "Visby-regular";
    font-size: 1rem;
    color: var(--gray-95);
    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }

  button {
    border: 1px solid var(--yellow-10);
    border-radius: 8px;
    height: 3.1rem;
    width: 11rem;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    background-color: transparent;
    color: var(--yellow-10);
    font-family: "Visby-light";
    font-size: 1.2rem;
    cursor: pointer;

    @media screen and (max-width: 500px) {
      height: 1rem;
      padding: 1rem;
      font-size: 1rem;
      width: 9rem;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 35rem;
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 1200px) {
    width: 30rem;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const ContentInnerBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  @media screen and (max-width: 600px) {
    justify-content: space-around;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 11rem;

  strong {
    color: var(--black);
    font-family: "Visby-demi-bold";
  }

  a {
    text-decoration: none;
    color: var(--primary);

    :hover {
      text-decoration: underline;
    }
  }
`;

export const StoreImage = styled.img`
  width: 100%;
  height: 13rem;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
`;

export const ContentSelect = styled.div`
  display: flex;
  width: 50%;

  select {
    :disabled {
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 768px) {
    select {
      margin-top: 1rem;
    }
    width: 100%;
    flex-direction: column;
  }
`;

export const UnselectedStore = styled.p`
  padding: 8rem 0;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 4rem 0;
  }
`;

export const DefaultImage = styled.img`
  width: 100%;
  height: 13rem;
  border-radius: 5px 5px 0 0;
  object-fit: contain;
`;
