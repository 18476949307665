import styled from "styled-components";
import { ContainerWidth } from "../../styles/globalStyles";

export const PageContainer = styled.div``;

export const Container = styled(ContainerWidth)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .titleFirst {
    margin-bottom: 0.5rem;
  }
  .titlePrinciples {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
    .titlePrinciples {
      margin-bottom: 0;
    }
  }
  ${ContainerWidth}
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;

  p {
    padding: 0 6.8rem;
    margin-bottom: 2.6rem 0;
    text-align: center;
    width: 100%;
    color: var(--gray-45);
    font-size: 1.5rem;
  }

  @media screen and (max-width: 575px) {
    p {
      padding: 0;
      text-align: start;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 0;
    p {
      font-size: 1rem;
    }
  }
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: 0;
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 1.2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;

  margin: 1rem 0;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 1100px) {
    grid-template-rows: 50% 50%;
  }
  @media screen and (max-width: 575px) {
    margin: 0;
    grid-auto-flow: row;
    grid-template-rows: auto;
  }
`;

export const IconsCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  margin: 0 3.5rem;
  height: fit-content;

  @media screen and (max-width: 1100px) {
    &:first-child {
      margin-left: 3.5rem;
    }
    &:last-child {
      margin-right: 3.5rem;
    }
    @media screen and (max-width: 500px) {
    }
  }
`;

export const Icons = styled.img`
  margin-top: 1rem;
  width: 3rem;

  @media screen and (max-width: 500px) {
    width: 2.5rem;
  }
`;

export const IconTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 0.8rem;
  min-width: 8.6rem;

  color: var(--gray-180);
  font-family: "Visby-regular";
  font-size: 1.25rem;
  font-weight: 400;

  b {
    text-align: center;
    font-weight: 500;
  }
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 1400px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }
`;

export const IconText = styled.div`
  text-align: center;
  font-family: "Visby-regular";
  font-weight: 400;
  color: var(--gray-150);
  @media screen and (max-width: 1400px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const TrajectoryContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;

  margin: 1.5rem 0;

  @media screen and (max-width: 1560px) {
    grid-template-columns: 38% 62%;
  }
  @media screen and (max-width: 1100px) {
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
`;

export const TrajectoryImg = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
  @media screen and (max-width: 1000px) {
    margin: 1rem 0;
    z-index: 2;
    position: center;
    width: 43.25rem;
    height: 43.25rem;
  }

  @media screen and (max-width: 500px) {
    width: 23rem;
    height: 23rem;
  }

  @media screen and (max-width: 400px) {
    width: 20rem;
    height: 20rem;
  }
`;

export const TrajectoryTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0 2%;
  @media screen and (max-width: 1080px) {
    padding: 2%;
  }
  @media screen and (max-width: 880px) {
    padding: 2% 0;
    justify-content: left;
  }
`;

export const TrajectoryTitle = styled.h1`
  font-family: "Visby-demi-bold";
  font-size: 3.7rem;
  font-weight: 400;

  strong {
    color: var(--primary);
  }
  @media screen and (max-width: 1760px) {
    font-size: 3.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 1400px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 1.5rem;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

export const TrajectoryText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: justify;

  font-family: "Visby-regular";
  color: var(--gray-150);
  a {
    cursor: pointer;
    color: var(--primary);
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0;
    text-decoration: none;

    &:hover {
      display: inline-block;
      position: relative;
      color: var(--primary);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--primary);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  p {
    letter-spacing: 0.5px;
    font-size: 1.25rem;
  }
  @media screen and (max-width: 1560px) {
    p,
    a {
      font-size: 1.1rem;
    }
  }
  @media screen and (max-width: 1080px) {
    padding-top: 2%;
  }
  @media screen and (max-width: 880px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 575px) {
    p {
      text-align: start;

      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 400px) {
    p,
    a {
      font-size: 0.9rem;
    }
  }
`;

export const TimeLineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1.5rem;
  @media screen and (max-width: 575px) {
    margin-top: 0;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1.5rem;
`;
export const VideoRep = styled.iframe`
  width: 866px;
  height: 487px;

  @media screen and (max-width: 900px) {
    width: 579px;
    height: 326px;
  }
  @media screen and (max-width: 575px) {
    width: 435px;
    height: 245px;
  }
  @media screen and (max-width: 400px) {
    width: 335px;
    height: 245px;
  }
`;
export const PrinciplesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1.5rem 0 3rem;

  @media screen and (max-width: 575px) {
    margin-top: 0;
  }
`;

export const PrinciplesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;

  width: 100%;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
  @media screen and (max-width: 690px) {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
`;

export const PrinciplesCards = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 18rem;
  margin: 1.5rem 0;
  @media screen and (max-width: 1400px) {
    height: 25rem;
  }
  @media screen and (max-width: 1300px) {
    height: 24rem;
  }
  @media screen and (max-width: 1010px) {
    height: 28rem;
  }
  @media screen and (max-width: 920px) {
    height: 30rem;
  }
  @media screen and (max-width: 768px) {
    height: 35rem;
  }

  @media screen and (max-width: 690px) {
    height: 100%;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const PrinciplesIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;
export const PrinciplesIcon = styled.img`
  padding: 0.2rem;
  margin-top: 0.5rem;

  height: 5rem;
  @media screen and (max-width: 1100px) {
    height: 4rem;
  }
  @media screen and (max-width: 860px) {
    height: 3rem;
  }
  @media screen and (max-width: 660px) {
    height: 5rem;
  }
  @media screen and (max-width: 400px) {
    height: 4rem;
  }
`;

export const PrinciplesTextContainer = styled.div`
  padding: 0.5rem 1rem;
`;

export const PrinciplesTitle = styled.h1`
  font-size: 1.5rem;
  letter-spacing: 0;
  margin-bottom: 1rem;
  font-family: "Visby-bold";

  @media screen and (max-width: 575px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;

export const PrinciplesText = styled.p`
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0;

  @media screen and (max-width: 1560px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 575px) {
    text-align: left;
  }
`;
