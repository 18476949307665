import { Helmet } from "react-helmet-async";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import {
  Container,
  ContentTable,
  ContentText,
  ParagraphTitle,
  Title,
} from "./styles";

export const Privacidade = () => {
  return (
    <>
      <Helmet>
        <title>The Best Açaí - Privacidade</title>
        <link rel="canonical" href={`https://thebestacai.com.br/privacidade`} />
      </Helmet>
      <Navbar />
      <Container>
        <ContentText>
          <Title>Aviso de Privacidade</Title>
          <p>Bem-vindo(a).</p>
          <p>
            Nós, do Grupo The Best, levamos a sua privacidade e a proteção dos
            seus dados a sério, motivo pelo qual o presente aviso de privacidade
            demonstra, publicamente, como tratamos seus{" "}
            <strong>dados pessoais</strong>, quais são os seus{" "}
            <strong>direitos</strong> e como{" "}
            <strong>você pode exercê-los</strong>.
          </p>
          <br />
          <p>
            Este aviso faz parte do nosso Programa de Conformidade à Lei Geral
            de Proteção de Dados Pessoais e outras leis setoriais sobre o tema,
            e por isso, deve ser lido e interpretado conjuntamente com as atuais
            leis de proteção de dados.
          </p>
          <br />
          <p>
            A partir do conteúdo apresentado você poderá compreender por que
            meios coletamos dados pessoais, como ocorre esta coleta e uso,
            dentre outros tratamentos que realizamos. Afinal, o Grupo The Best é
            o Controlador de Dados, sendo responsável no limite de suas
            respectivas obrigações.
          </p>

          <ParagraphTitle>
            Conceitos básicos estampados neste aviso
          </ParagraphTitle>
          <p>
            Com o único objetivo de descomplicar a leitura deste aviso,
            apresentamos algumas definições úteis para a sua interpretação:
          </p>

          <ContentTable>
            <thead>
              <tr>
                <th>Termo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="table_td">Dado pessoal</td>
                <td>
                  É uma informação relacionada a uma pessoa física e que seja
                  capaz de identificar a pessoa ou tornar possível a sua
                  identificação. São exemplos de dados pessoais que podem
                  permitir a sua identificação: Nome, CPF, telefone, e-mail,
                  nome do(s) representante(s) legal(is), etc.
                </td>
              </tr>

              <tr>
                <td className="table_td">Tratamento</td>
                <td>
                  É toda forma de uso que podemos fazer dos seus Dados Pessoais,
                  incluindo, mas não se limitando às seguintes atividades:
                  coleta, armazenamento, consulta, uso, compartilhamento,
                  transmissão, classificação, reprodução, exclusão e avaliação.{" "}
                </td>
              </tr>

              <tr>
                <td className="table_td">Titular</td>
                <td>
                  É você, a pessoa física a quem os Dados Pessoais se referem.{" "}
                </td>
              </tr>
              <tr>
                <td className="table_td">Grupo The Best</td>
                <td>
                  Serão consideradas empresas do universo Grupo The Best: Grupo
                  The Best Franchising LTDA, CNPJ nº 28.967.949/0001-84 Amadelli
                  Alimentos Ltda, CNPJ nº 34.367.762/0001-42 Amabest PR LTDA,
                  CNPJ nº 30.339.500/0001-13 Grupo S2M LTDA, CNPJ nº
                  42.525.057/0001-54.
                </td>
              </tr>
              <tr>
                <td className="table_td">Aviso</td>
                <td>É este Aviso de Privacidade</td>
              </tr>
              <tr>
                <td className="table_td">Encarregado</td>
                <td>
                  É o nosso Encarregado de Proteção de Dados (Data Protection
                  Officer) que atua como canal de comunicação entre o Grupo The
                  Best, os Titulares de Dados e Autoridade Nacional de Proteção
                  de Dados, quando o assunto é a proteção dos seus dados
                  pessoais.
                </td>
              </tr>
              <tr>
                <td className="table_td">Legítimo interesse</td>
                <td>
                  É uma das dez bases legais que permite que dados pessoais
                  sejam tratados, sempre que o uso desses dados seja necessário
                  ao atendimento de interesses legítimos nossos, como no
                  contexto de atendimento à sua solicitação, no qual pode ser
                  necessário que você forneça algumas confirmações de seus dados
                  para que possamos ter certeza de que estamos deliberando com
                  você.
                </td>
              </tr>
              <tr>
                <td className="table_td">Bases legais</td>
                <td>
                  São as hipóteses autorizativas que nos permitam a tratar dados
                  pessoais: pode ser o seu consentimento, a necessidade de
                  cumprir um contrato que temos com você, cumprimento de uma
                  obrigação legal ou regulatória, por exemplo.
                </td>
              </tr>
            </tbody>
          </ContentTable>

          <ParagraphTitle>
            Como seus dados pessoais são coletados e quais os tipos de dados
            coletados?
          </ParagraphTitle>
          <p>
            Para saber mais sobre quais os dados pessoais coletados e suas
            respectivas finalidades, consulte nossa tabela de dados pessoais
            abaixo disponibilizada:
          </p>

          <ContentTable>
            <thead>
              <tr>
                <th className="type_table">Origem</th>
                <th className="type_table">Tipo de dados coletados</th>
                <th className="type_table">Finalidade</th>
                {window.outerWidth > 600 ? (
                  <th className="type_table">Base legal</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {window.outerWidth > 600 ? (
                <>
                  {" "}
                  <tr>
                    <td className="table_td">Navegação no site</td>
                    <td>
                      Dados de navegação: dados coletados por meio de cookies,
                      incluindo IP, data e hora de acesso, localização
                      geográfica.
                    </td>
                    <td>
                      Registros de acesso: registrar o seu IP, data e hora de
                      acesso.
                    </td>
                    <td>
                      Cumprimento de obrigação legal (Marco Civil da Internet).
                    </td>
                  </tr>
                  <tr>
                    <td className="table_td">
                      Formulário "Seja um franqueado"
                    </td>
                    <td>
                      Nome completo, telefone (WhatsApp), e-mail, cidade e
                      estado.
                    </td>
                    <td>
                      Enviar ao interessado mais informações sobre a abertura de
                      uma loja no modelo da nossa franquia.
                    </td>
                    <td>Consentimento.</td>
                  </tr>
                  <tr>
                    <td className="table_td">Pesquisa de Satisfação (NPS)</td>
                    <td>Nome completo, CPF, telefone.</td>
                    <td>
                      Realização de campanhas de relacionamento com clientes do
                      The Best Açaí.
                    </td>
                    <td>Consentimento.</td>
                  </tr>
                  <tr>
                    <td className="table_td">Nota fiscal (lojas)</td>
                    <td>Nome completo e CPF</td>
                    <td>Emissão de nota fiscal</td>
                    <td>Cumprimento de obrigação legal.</td>
                  </tr>
                  <tr>
                    <td className="table_td">
                      Formulário de Inscrição de Trainee
                    </td>
                    <td>
                      Nome completo, data de nascimento, nacionalidade, CPF,
                      celular, e-mail, endereço de residência, qualificações
                      acadêmicas, profissionais e quaisquer outras informações
                      que você nos forneceu em seu Currículo (CV), URL de rede
                      social.
                    </td>
                    <td>
                      (i) Comunicar-se com o candidato e esclarecer eventuais
                      dúvidas sobre a vaga; (ii) recrutamento, avaliação e
                      contratação; (iii) analisar e melhorar o portal de
                      Trainee.
                    </td>
                    <td>Consentimento.</td>
                  </tr>
                  <tr>
                    <td className="table_td">Dados de localização</td>
                    <td>
                      Localização obtida do seu dispositivo via GPS e dados
                      móveis, como torres de celular, Wi-Fi e outras hipóteses
                      de localização
                    </td>
                    <td>
                      Oferecer a possibilidade do usuário localizar a loja “The
                      Best Açaí” mais próxima.
                    </td>
                    <td>Consentimento.</td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td className="table_td">Navegação no site</td>
                    <td>
                      Dados de navegação: dados coletados por meio de cookies,
                      incluindo IP, data e hora de acesso, localização
                      geográfica.
                    </td>
                    <td>
                      Registros de acesso: registrar o seu IP, data e hora de
                      acesso.
                    </td>
                  </tr>

                  <tr>
                    <td className="table_td">
                      Formulário "Seja um franqueado"
                    </td>
                    <td>
                      Nome completo, telefone (WhatsApp), e-mail, cidade e
                      estado.
                    </td>
                    <td>
                      Enviar ao interessado mais informações sobre a abertura de
                      uma loja no modelo da nossa franquia.
                    </td>
                  </tr>

                  <tr>
                    <td className="table_td">Informações</td>
                    <td>
                      Cumprimento de obrigação legal (Marco Civil da Internet).
                    </td>
                    <td>Consentimento.</td>
                  </tr>
                </>
              )}
            </tbody>
          </ContentTable>

          <ParagraphTitle>
            Com quem o Grupo The Best compartilha seus dados pessoais?
          </ParagraphTitle>
          <p>
            Em determinadas situações, precisamos compartilhar os seus Dados
            Pessoais com terceiros que prestam serviços em nosso nome. Abaixo
            você identificará as situações nas quais podemos compartilhar os
            seus Dados Pessoais.
          </p>

          <ContentTable>
            <tbody>
              <tr>
                <td className="type_table">Autoridades Públicas</td>
                <td>
                  Se um magistrado ou uma autoridade com competência legal
                  exigir que o Grupo The Best compartilhe certos dados pessoais
                  para, por exemplo, uma investigação, vamos compartilhar. Somos
                  contra qualquer abuso de autoridade e, caso o Grupo The Best
                  entenda que determinada ordem é abusiva, vamos sempre defender
                  a privacidade dos titulares.
                </td>
              </tr>

              <tr>
                <td className="type_table">Grupo The Best</td>
                <td>
                  Suas informações podem ser compartilhadas entre as empresas e
                  áreas de negócio do Grupo The Best, as quais estão de acordo
                  com o presente Aviso de Privacidade. Fazemos isso para (a)
                  atender aos legítimos interesses do Grupo The Best e aos seus
                  interesses, (b) análise de dados, (c) segmentação do seu
                  perfil para lhe proporcionar experiências cada vez mais
                  personalizadas e (d) higienizar seus Dados, justamente para
                  que Você não deixe de receber nossas comunicações.
                </td>
              </tr>

              <tr>
                <td className="type_table">Terceiros</td>
                <td>
                  Com empresas parceiras e fornecedores, no desenvolvimento e
                  prestação de serviços disponibilizados a você, sobretudo
                  empresas de armazenamento em nuvem e transportadoras.
                </td>
              </tr>

              <tr>
                <td className="type_table">
                  Empresas envolvidas em mudança de quadro societário (em razão
                  de fusão, aquisição, cisão, etc):
                </td>
                <td>
                  Com o desenvolvimento de nossas operações, podemos comprar ou
                  vender outras empresas e serviços. Nesses casos, as
                  informações de usuários costumam ser um dos ativos
                  transferidos, continuando, porém, sujeitos às condições
                  definidas em Avisos de Privacidade já existentes (a não ser
                  que haja seu consentimento para uso de outras formas). Além
                  disso, no caso improvável de que o Grupo The Best ou
                  substancialmente todos os seus ativos sejam adquiridos por
                  terceiros, as informações do usuário serão um dos ativos
                  transferidos.
                </td>
              </tr>

              <tr>
                <td className="type_table">
                  Proteção do Grupo The Best e terceiros:
                </td>
                <td>
                  Disponibilizamos contas e outras informações pessoais quando
                  acreditamos que a disponibilização é apropriada para cumprir a
                  lei ou nossas obrigações regulatórias; fazer cumprir ou
                  aplicar nossos Termos de Uso e outros acordos; ou proteger os
                  direitos, propriedade ou segurança do Grupo The Best, nossos
                  usuários ou terceiros. Isso inclui a troca de informações com
                  outras empresas e organizações para proteção contra fraudes e
                  redução do risco de crédito.
                </td>
              </tr>
              <tr>
                <td className="type_table">
                  Registradoras de Recebíveis de cartão
                </td>
                <td>
                  O Grupo The Best poderá compartilhar informações cadastrais e
                  transacionais da unidade franqueada com a MARVIN SOLUÇÕES EM
                  SERVIÇOS DE TECNOLOGIA LTDA., sociedade empresária limitada,
                  inscrita no CNPJ/ME. sob o nº 41.240.161/0001-30, com sede no
                  Município e Estado de São Paulo, na Rua Doutor Renato Paes de
                  Barros, 955, conjunto 132, Itaim Bibi, CEP 04.530-001, com a
                  finalidade de consultar sua agenda de recebíveis perante as
                  registradoras de recebíveis de cartão e prestar serviços de
                  pagamento, na medida que o compartilhamento de informações
                  seja necessário para a viabilização e fomento de parcerias
                  inerentes a atividade, qualidade e aperfeiçoamento da
                  prestação de serviços pelo Grupo The Best e/ou pela Marvin.
                </td>
              </tr>
            </tbody>
          </ContentTable>

          <ParagraphTitle>
            Por quanto tempo seus dados pessoais são armazenados?
          </ParagraphTitle>
          <p>
            Seguindo as diretrizes da Lei Geral de Proteção de Dados, o Grupo
            The Best armazena os seus Dados Pessoais somente pelo tempo que
            forem necessários para cumprir com as finalidades para as quais
            foram coletados, salvo se houver qualquer outra razão para sua
            manutenção como, por exemplo, cumprimento de quaisquer obrigações
            legais, contratuais, entre outras permitidas sob a lei.
          </p>
          <br />
          <p>
            Sempre fazemos uma análise técnica para determinar o período de
            retenção adequado para cada tipo de Dado Pessoal coletado,
            considerando a sua natureza, necessidade de coleta e finalidade para
            a qual ele será tratado, bem como eventuais necessidades de retenção
            para o cumprimento de obrigações ou o resguardo de direitos.
          </p>

          <ParagraphTitle>
            Quais são os seus direitos como titular de dados pessoais?
          </ParagraphTitle>

          <p>
            Os Dados Pessoais são seus e a lei brasileira lhe garante uma série
            de direitos relacionados a eles. Nós estamos comprometidos com o
            cumprimento desses direitos e, nessa seção, vamos explicar quais são
            esses direitos e como você pode exercê-los.
          </p>

          <ContentTable>
            <thead>
              <tr>
                <th className="table_td">Seus direitos</th>
                <th className="table_td">Explicação</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="type_table">Confirmação e acesso</td>
                <td>
                  Você pode solicitar ao Grupo The Best a confirmação sobre a
                  existência de tratamento dos seus Dados Pessoais para que, em
                  caso positivo, você possa acessá-los, inclusive por meio de
                  solicitação de cópias dos registros que temos sobre você.
                </td>
              </tr>

              <tr>
                <td className="type_table">Correção</td>
                <td>
                  Você pode solicitar a correção dos seus Dados Pessoais caso
                  estes estejam incompletos, inexatos ou desatualizados.
                </td>
              </tr>

              <tr>
                <td className="type_table">Portabilidade</td>
                <td>
                  Você pode solicitar que o Grupo The Best forneça os seus Dados
                  Pessoais em formato estruturado e interoperável visando à sua
                  transferência para um terceiro, desde que essa transferência
                  não viole a propriedade intelectual ou segredo de negócios da
                  organização.
                </td>
              </tr>

              <tr>
                <td className="type_table">Oposição</td>
                <td>
                  A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu
                  consentimento ou um contrato conosco. Nessas situações,
                  somente trataremos seus Dados Pessoais se tivermos motivos
                  legítimos para tanto, como, por exemplo, quando for necessário
                  para garantir a segurança de nossas unidades. Caso você não
                  concorde com alguma finalidade de tratamento dos seus Dados
                  Pessoais, você poderá apresentar oposição, solicitando a sua
                  interrupção.
                </td>
              </tr>
            </tbody>
          </ContentTable>
          <br />
          <p>
            Sobre esse assunto, ressalta-se que Você não tem a obrigação de
            fornecer os Dados Pessoais que solicitamos, porém, caso escolha não
            fornecê-los, pode ser que não consigamos lhe atender com nossos
            serviços ou responder suas dúvidas. Para sua segurança, sempre que
            você apresentar uma requisição para exercer seus direitos, o Grupo
            The Best poderá solicitar algumas informações e/ou documentos
            complementares, a fim de impedir fraudes. Em alguns casos, o Grupo
            The Best pode ter motivos legítimos para deixar de atender a uma
            solicitação de exercício de direitos. Tais situação podem ocorrer em
            casos que uma revelação de informações específicas poderia violar
            direitos de propriedade intelectual ou segredos de negócio do Grupo
            The Best ou de terceiros. Quando as solicitações são passíveis de
            atendimento, pode ocorrer de não serem respondidas de forma
            imediata. Contudo, o Grupo The Best se compromete a responder todas
            as requisições em um prazo razoável e sempre em conformidade com a
            legislação aplicável. Caso você tenha interesse em promover alguma
            requisição, contate o Encarregado (DPO) do Grupo The Best por meio
            do e-mail lucas@gomesaltimari.com.br.
          </p>

          <ParagraphTitle>
            Como o grupo The Best protege seus dados pessoais?
          </ParagraphTitle>

          <p>
            Nossa responsabilidade é cuidar dos seus Dados Pessoais e
            utilizá-los somente para as finalidades descritas nesse Aviso. Para
            garantir a sua privacidade e a proteção dos seus Dados Pessoais,
            adotamos recursos tecnológicos avançados para garantir a segurança
            de todos os dados tratados pelo Grupo The Best. Entre as medidas de
            segurança implementadas estão a implementação de controles de acesso
            a sistemas, técnicas de criptografia, instalação de barreiras contra
            o acesso indevido às bases de dados (incluindo firewalls), entre
            outros controles de segurança da informação.
          </p>
          <br />
          <p>
            Nós nos esforçamos para proteger a privacidade de seus Dados
            Pessoais, mas infelizmente não podemos garantir total segurança.
            Entradas e usos não autorizados de terceiros com informações suas,
            falhas de hardware ou software que não estejam sob controle do Grupo
            The Best e outros fatores externos podem comprometer a segurança dos
            seus Dados Pessoais. Por isso, sua atuação é fundamental para a
            manutenção de um ambiente seguro para todos. Caso você identifique
            ou tome conhecimento de qualquer fator que comprometa a segurança
            dos seus dados na sua relação com o Grupo The Best, entre em contato
            conosco por meio das informações de contato indicadas abaixo.
          </p>

          <ParagraphTitle>Incidentes de dados e notificações</ParagraphTitle>

          <p>
            A Lei Geral de Proteção de Dados define a "violação de dados
            pessoais" como uma violação de segurança que leva à destruição
            acidental ou ilegal, perda, alteração, divulgação não autorizada ou
            acesso a Dados Pessoais transmitidos, armazenados ou processados
            ​​de outra forma. Em caso de incidente de segurança da informação
            que ocorra destruição, perda, alteração, acesso não autorizado ou
            ainda incidente envolvendo Dados Pessoais que cause dano relevante
            ao Titular de Dados (depois de avaliado), nós procederemos a
            comunicação do incidente ao Titular e/ou à ANPD utilizando, no
            mínimo, dos requisitos e informações delimitadas pela Autoridade
            Nacional de Proteção de Dados.
          </p>

          <ParagraphTitle>
            Dúvidas? Converse com nosso encarregado!
          </ParagraphTitle>
          <p>
            Se você acredita que seus Dados Pessoais foram tratados de maneira
            incompatível com este Aviso ou com as suas escolhas enquanto Titular
            dos seus Dados Pessoais, se soube de algum incidente de Dados
            Pessoais tratados pelo Grupo The Best ou, ainda, se você tiver
            dúvidas, comentários ou sugestões relacionadas a este Aviso e à
            forma como tratamos seus Dados Pessoais, entre em contato conosco.
            Nós temos um Encarregado que está à disposição no seguinte endereço
            de contato:
          </p>
          <br />
          <p>
            <strong>Encarregado:</strong> Gomes Altimari Advogados (Lucas
            Colombera Vaiano Piveto)
          </p>
          <p>
            <strong>E-mail para contato</strong> lucas@gomesaltimari.com.br
          </p>

          <ParagraphTitle>Alterações no aviso de privacidade</ParagraphTitle>

          <p>
            Como estamos sempre buscando melhorar os nossos serviços e a forma
            como operamos, este Aviso pode passar por atualizações para refletir
            as melhorias realizadas. Desta forma, o Grupo The Best recomenda a
            visita periódica desta página para que você tenha conhecimento sobre
            as modificações efetivadas!
          </p>
          <br />

          <p>
            <strong>Data da última atualização:</strong> 17 de Junho de 2024.
          </p>
        </ContentText>
        <Footer />
      </Container>
    </>
  );
};
