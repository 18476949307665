import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  width: 13rem;
  height: 13rem;
  :hover > .card {
    transform: rotateY(180deg);
  }
`;

export const Card = styled.div`
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  transform-style: preserve-3d;
  transition: transform 1000ms;
  cursor: pointer;


  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 10.5rem;
  }
`;

export const Front = styled.div`
  position: absolute;
  height: 12.5rem;
  width: 12.5rem;

  backface-visibility: hidden;
  -webkit-backface-visibility:hidden ;

  box-shadow: -7px 9px 5px -2px rgba(0,0,0,0.15);
  border: 1px solid var(--gray-50);
  border-radius: 5px;

  padding: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  overflow: hidden;

  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 10.5rem;
  }

  img {
    width: 4rem;

    @media screen and (max-width: 1024px) {
      width: 3rem;
    }
  }

  p {
    font-family: "Visby-medium";
    font-size: 1.2rem;
    margin-top: 0.6rem;
    letter-spacing: normal;

    @media screen and (max-width: 1024px) {
      font-size: 1.1rem;
    }
  }
`;

export const Back = styled.div`
  position: absolute;
  height: 12.5rem;
  width: 12.5rem;

  backface-visibility: hidden;
  -webkit-backface-visibility:hidden ;

  transform: rotateY(180deg);
  box-shadow: -7px 9px 5px -2px rgba(0,0,0,0.15);
  border: 1px solid var(--gray-50);
  border-radius: 5px;

  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-color: var(--white);

  overflow: hidden;
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-family: "Visby-demi-bold";
    color: var(--black);
    z-index: 1;
  }

  p {
    font-family: "Visby-regular";
    font-size: 1rem;
    margin: 0 1rem;
    line-height: normal;
    letter-spacing: normal;
    z-index: 2;
  }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14.4px;
    }
  }

  ::before {
    content: "";
    position: absolute;
    width: 5rem;
    height: 20rem;
    background-color: var(--primary);
    animation: rotate 8s linear infinite;
    -webkit-animation: rotate 8s linear infinite;

  }

  ::after {
    content: "";
    position: absolute;
    background: var(--white);
    inset: 4.8px;
    border-radius: 4.8px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

 

  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 10.5rem;
  }

  @media screen and (max-width: 420px) {
    p {
      font-size: 12.8px;
    }
  }
`;
