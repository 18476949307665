import React from "react";
import {
  ButtonBanner,
  ContainerImg,
  ContentText,
  Img,
  ImgMobile,
} from "./styles";

interface IBannerProps {
  img: string;
  imgMobile: string;
  alt: string;
  text?: boolean;
  page?: string;
  onClickEvent?: () => void;
}

export const BannerHome: React.FC<IBannerProps> = ({
  img,
  imgMobile,
  alt,
  text,
  page,
  onClickEvent,
}) => {
  const buttonEvent = () => {
    if (onClickEvent) {
      onClickEvent();
    }
  };

  return (
    <>
      {text ? (
        <ContainerImg text={text} img={img} imgMobile={imgMobile}>
          <ContentText>
            <h1 className={`${page}`}>
              baixe nosso <h1 className="textPrimary">app</h1> e garanta seu
              açaí <h1 className="textPrimary">hoje mesmo!</h1>
            </h1>
            <ButtonBanner onClick={() => buttonEvent()}>
              Baixe nosso app!
            </ButtonBanner>
          </ContentText>
        </ContainerImg>
      ) : (
        <ContainerImg>
          <Img src={img} alt={alt} />
          <ImgMobile src={imgMobile} alt={alt} />
        </ContainerImg>
      )}
    </>
  );
};
