import { RxMagnifyingGlass } from "react-icons/rx";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 80rem;
  margin: 2rem auto 7rem auto;
  padding: 0 1rem;

  gap: 2rem;
  align-items: flex-start;

  .modalContainer {
    display: flex;
    flex-direction: column;
    max-width: 70rem;
    max-height: 50rem;
    border-radius: 2rem;
  }
  .modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .required {
      display: flex;
      color: var(--gray-75);
      font-size: 0.8rem;
      justify-content: space-between;
    }
    .quill {
      p {
        font-size: 1.3rem;
        letter-spacing: normal;
      }
    }
    .message {
      display: block;
      font-size: 1rem;
      color: red;
    }
    .saveButton {
      font-weight: bold;
      width: 8rem;
      height: 2rem;

      border: none;
      border-radius: 14px;

      background-color: var(--black);
      color: var(--white);

      cursor: pointer;
      transition: background 200ms;
      :hover {
        background-color: var(--primary);
      }
    }
  }
  .boxButton {
    display: flex;
    flex-direction: row-reverse;
    margin: 1rem 1rem 0 0;
  }
  .fileLabel {
    width: 10rem;
    height: 2rem;
    background: var(--gray-50);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-88);

    border: 2px dashed;

    cursor: pointer;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;

    :hover {
      color: var(--black);
      background: var(--gray-75);
    }

    :active {
      background: var(--primary);
    }
  }
  .fileSpan {
    font-size: 0.8rem;
  }

  #fileInput {
    display: none;
  }

  .titleInput {
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-75);
    padding: 1rem;
    transition: border 200ms;
    font-size: 1.2rem;
    ::placeholder {
      color: var(--gray-88);
      justify-content: center;
      align-items: center;
    }

    :focus {
      outline: none;
      border: 1px solid var(--gray-150);
    }
    :focus::placeholder {
      color: var(--gray-150);
    }
  }
  @media screen and (max-width: 1500px) {
    padding: 3rem 3rem;
  }
  @media screen and (max-width: 410px) {
    padding: 3rem 2rem;
  }
`;

export const ContainerPost = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2rem;

  .postLink {
    height: 34rem;
    width: 100%;
    text-decoration: none;
  }
  @media screen and (max-width: 1024px) {
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
`;

export const ContentInput = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;

  @media screen and (max-width:500px) {
    width: 100%;
  }
`;
export const InputFind = styled.input`
  width: 25rem;
  height: 2.5rem;
  border: 1px solid #f8aa00;
  padding: 0 2rem;
  border-radius: 5px;
  font-size: 1.1rem;

  &::placeholder {
    color: var(--black);
    opacity: 30%;
    font-size: 1rem;
    font-family: "Visby-regular";
  }
`;
export const BoxButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1rem;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  font-weight: bold;

  margin-right: 1rem;
  width: 7rem;
  height: 2rem;

  border: none;
  border-radius: 5px;

  background: var(--black);
  color: var(--white);

  transition: background 200ms;
  cursor: pointer;

  :hover {
    background: var(--primary);
  }
`;

export const Icon = styled(RxMagnifyingGlass)`
  position: absolute;
  color: #f9a003;
  display: flex;
  left: 60%;
  width: 1.5rem;
  height: 2rem;

  cursor: pointer;

  @media screen and (max-width: 1024px) {
    left: 65%;
    top: 5%;
  }

  @media screen and (max-width: 1024px) {
    left: 65%;
    top: 5%;
  }

  @media screen and (max-width: 768px) {
    left: 70%;
  }

  @media screen and (max-width: 425px) {
    left: 75%;
    top: 5%;
  }
`;

export const BoxImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  p {
    width: 15rem;
    color: var(--gray-75);
    font-size: 0.8rem;
    letter-spacing: normal;
    line-height: normal;
  }
`;

export const ContainerPreview = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImgPreview = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 0.2rem;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const Mandatory = styled.span`
  color: red;

  font-size: 1rem;
`;

export const ContainerEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  p {
    color: var(--gray-75);
    letter-spacing: normal;
  }
`;

export const ImageNotFound = styled.img`
  width: 20rem;
  height: 20rem;
  object-fit: contain;
  filter: grayscale(0.7);
`;
