import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import apiThor from "../../services/apiThor";
import s3handlerApi from "../../services/s3handlerApi";
import { removeAccents } from "../../helpers/removeAccents";
import { hasPermissions } from "../../services/user";

import { IPosts } from "../../model/posts";

import notFound from "../../assets/svg/notfoundpost.svg";

import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Post } from "../../components/Post";
import {
  BoxButton,
  BoxImg,
  Button,
  Container,
  ContainerEmpty,
  ContainerPost,
  ContainerPreview,
  ContentInput,
  Icon,
  ImageNotFound,
  ImgPreview,
  InputFind,
  Mandatory,
  Title,
} from "./styles";
import { Spinner } from "../../components/Spinner";
import { CustomModal } from "../../components/Modal";
import { Helmet } from "react-helmet-async";

export const Blog: React.FC = () => {
  const [search, setSearch] = useState("");

  const [shouldOpenPostModalCreate, setShouldOpenPostModalCreate] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const navigate = useNavigate();

  const [posts, setPosts] = useState<IPosts[]>([]);
  const [postData, setPostData] = useState<IPosts>({
    title: "",
    subtitle: "",
    description: "",
    url_image: "",
    s3_key: "",
    mime_type: "",
  });

  const imgBlog = require("../../assets/Images/Banners_site/banner-home.webp");

  //Validação
  const schema = yup.object().shape({
    title: yup.string().required("O título é obrigatório"),
    subtitle: yup.string().required("O subtítulo é obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const getAllPosts = async () => {
      setLoading(true);

      try {
        const response = await apiThor.get("/blog");
        setPosts(response.data.content);
      } catch (error) {
        console.log(error);
      } finally {
        setReload(false);
        setLoading(false);
      }
    };
    if (reload) {
      getAllPosts();
    }
  }, [reload]);

  const handleSubmitCreate = async () => {
    setLoading(true);
    try {
      let payload = postData;

      if (!image) {
        Swal.fire({
          title: "Selecione uma imagem!",
        });
        setLoading(false);
        return;
      }
      if (image) {
        const imgToUpload = new FormData();
        imgToUpload.append("file", image);

        const {
          data: { location, key },
        } = await s3handlerApi.post(
          "/s3-upload/upload/amadelli-blog-attachments",
          imgToUpload
        );

        payload.s3_key = key;
        payload.url_image = location;
      }
      await apiThor.post("/blog", payload);

      Swal.fire({
        icon: "success",
        title: "Post criado com sucesso!",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          setShouldOpenPostModalCreate(false);
          setReload(true);
          setLoading(true);
          navigate("/blog");
        }
      });
    } catch (error: any) {
      Swal.fire({
        title: "Ocorreu um erro!",
        text: "Você precisa preencher todos os campos de criação",
      });
      setLoading(false);
    }
  };

  const cleanInput = () => {
    let fileCleanInput = document.getElementById(
      "fileInput"
    ) as HTMLInputElement;
    if (fileCleanInput) {
      fileCleanInput.value = "";
    }
    setImage(null);
    setImagePreview(null);
  };

  const findPost = (value: string) => {
    const formattedValue = removeAccents(value);

    return formattedValue?.length
      ? posts.filter((post) =>
          removeAccents(post.title).includes(formattedValue)
        )
      : posts;
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Blog</title>
        <link rel="canonical" href={`https://thebestacai.com.br/blog`} />
        <meta property="og:image" content={imgBlog} />
        <meta name="twitter:image" content={imgBlog} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Navbar />
      <Container>
        <Title>Blog</Title>

        <ContentInput>
          <InputFind
            placeholder={"Pesquisar..."}
            onChange={({ target: { value } }) => setSearch(value)}
          />
          <Icon />
        </ContentInput>

        {hasPermissions("blog_create") && (
          <BoxButton>
            <Button onClick={() => setShouldOpenPostModalCreate(true)}>
              Criar Post
            </Button>
          </BoxButton>
        )}

        {posts.length !== 0 ? (
          <ContainerPost>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {findPost(search).length !== 0 && (
                  <>
                    {posts
                      .filter((item) =>
                        findPost(search).some(
                          (searchedItem) => searchedItem.id === item.id
                        )
                      )
                      .map((post) => (
                        <Link
                          to={`/blog/${post.slug}`}
                          className="postLink"
                          key={post.title}
                          state={{
                            previousPost: posts,
                          }}
                        >
                          <Post
                            title={post.title}
                            subtitle={post.subtitle}
                            url_image={post.url_image}
                            description={post.description}
                            created_at={post.created_at}
                          />
                        </Link>
                      ))}
                  </>
                )}
              </>
            )}
          </ContainerPost>
        ) : (
          <ContainerEmpty>
            <ImageNotFound
              src={notFound}
              alt="Imagem representando que não há posts no momento"
            />

            <p>Não há posts no momento...</p>
          </ContainerEmpty>
        )}

        <CustomModal
          isActive={shouldOpenPostModalCreate}
          title="Nova postagem"
          handleClose={() => setShouldOpenPostModalCreate(false)}
          key={1}
        >
          <div className="modalContainer">
            <form className="modal">
              <label htmlFor="title">
                Título
                <Mandatory>*</Mandatory>
              </label>
              <input
                className="titleInput"
                type="text"
                maxLength={100}
                placeholder="Digite o título..."
                {...register("title")}
                onChange={(event) =>
                  setPostData({ ...postData, title: event.target.value })
                }
              />
              <div className="required">
                <span className="message">{errors.title?.message}</span>

                <span>Máximo 100 caracteres</span>
              </div>
              <label htmlFor="subtitle">
                Subtitulo
                <Mandatory>*</Mandatory>
              </label>
              <input
                className="titleInput"
                type="text"
                maxLength={150}
                placeholder="Digite o subtítulo..."
                {...register("subtitle")}
                onChange={(event) =>
                  setPostData({ ...postData, subtitle: event.target.value })
                }
              />
              <div className="required">
                <span className="message">{errors.subtitle?.message}</span>

                <span>Máximo 150 caracteres</span>
              </div>
              <BoxImg>
                <Mandatory>Obrigatório *</Mandatory>
                <p>
                  Tamanho máx recomendado: <strong>300 KB</strong>
                </p>
                <p>
                  Resolução recomendada: <strong> 1246x480</strong>
                </p>
              </BoxImg>
              <label htmlFor="fileInput" className="fileLabel" tabIndex={0}>
                <span className="fileSpan">Selecione a imagem</span>
              </label>

              <input
                accept="image/*"
                type="file"
                id="fileInput"
                onChange={({ target: { files } }) => {
                  if (files && files.length > 0) {
                    const selectedFiles = files[0];
                    const inputSize = 300 * 1024;
                    if (selectedFiles.size <= inputSize) {
                      setImage(selectedFiles);

                      const reader = new FileReader();
                      reader.onload = (preview) => {
                        // @ts-ignore
                        setImagePreview(preview.target.result as string);
                      };
                      reader.readAsDataURL(selectedFiles);
                    } else {
                      Swal.fire({
                        text: "Tamanho de imagem inválido",
                      });
                    }
                  }
                }}
              />
              {imagePreview && (
                <ContainerPreview>
                  <ImgPreview
                    src={imagePreview}
                    alt="Imagem Selecionada"
                    className="imagePreview"
                  />
                  <Button onClick={cleanInput}>Excluir</Button>
                </ContainerPreview>
              )}
              <label htmlFor="text">
                Descrição
                <Mandatory>*</Mandatory>
              </label>
              <ReactQuill
                onChange={(value) =>
                  setPostData({ ...postData, description: value })
                }
                id="description"
              />
              {loading ? (
                <Spinner />
              ) : (
                <div className="boxButton">
                  <Button
                    type="submit"
                    onClick={handleSubmit(handleSubmitCreate)}
                  >
                    Salvar post
                  </Button>
                </div>
              )}
            </form>
          </div>
        </CustomModal>
      </Container>
      <Footer active={true} />
    </>
  );
};
