import styled, { css } from "styled-components";
import {
  Facebook,
  Twitter,
  EditAlt,
  TrashAlt,
  ArrowRight,
} from "../../styles/icons";

export const Container = styled.div`
  max-width: 80rem;
  margin: 2.5rem auto 10rem auto;
  padding: 0 1rem;

  gap: 2rem;
  align-items: flex-start;

  hr {
    filter: opacity(0.2);
    margin-top: 2.5rem;
  }

  @media screen and (max-width: 1500px) {
    padding: 0rem 3rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    max-width: 70rem;
    max-height: 50rem;
    border-radius: 2rem;
  }
  .modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    label {
      font-size: 1.5rem;
      font-weight: bold;
    }
    .required {
      display: flex;
      color: var(--gray-75);
      font-size: 0.8rem;
      flex-direction: row-reverse;
    }
    .quill {
      p {
        font-size: 1.3rem;
        letter-spacing: normal;
      }
    }
    .message {
      color: red;
    }
    .saveButton {
      font-weight: bold;
      width: 8rem;
      height: 2rem;

      border: none;
      border-radius: 14px;

      background-color: var(--black);
      color: var(--white);

      cursor: pointer;
      transition: background 200ms;
      :hover {
        background-color: var(--primary);
      }
    }
  }
  .boxButton {
    display: flex;
    flex-direction: row-reverse;
    margin: 1rem 1rem 0 0;
  }
  .fileLabel {
    width: 10rem;
    height: 2rem;
    background: var(--gray-50);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-88);

    border: 2px dashed;

    cursor: pointer;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;

    :hover {
      color: var(--black);
      background: var(--gray-75);
    }

    :active {
      background: var(--primary);
    }
  }
  .fileSpan {
    font-size: 0.8rem;
  }

  #fileInput {
    display: none;
  }

  .titleInput {
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-75);
    padding: 1rem;
    transition: border 200ms;
    font-size: 1.2rem;
    ::placeholder {
      color: var(--gray-88);
      justify-content: center;
      align-items: center;
    }

    :focus {
      outline: none;
      border: 1px solid var(--gray-150);
    }
    :focus::placeholder {
      color: var(--gray-150);
    }
  }

  .postLink {
    height: 34rem;
    width: 100%;
    text-decoration: none;
  }
  @media screen and (max-width: 1500px) {
    padding: 3rem 3rem;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;

  margin: 2rem 0 1rem 0;

  h1 {
    text-align: center;
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.2rem;
    margin: 0 3rem;

    @media screen and (max-width: 1100px) {
      margin: 0 2rem;
    }
  }

  span {
    color: var(--gray-75);
    margin-top: 2rem;
    gap: 1rem;
  }
  p {
    color: var(--gray-75);
    letter-spacing: normal;
    font-size: 1rem;
  }
`;

export const HeaderPost = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Publishers = styled.div`
  display: flex;
  justify-content: end;
  gap: 8rem;

  margin-right: 9rem;

  :hover {
    background: var(--primary);
    color: var(--white);
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  @media screen and (max-width: 425px) {
    margin-right: 8rem;
  }
`;

export const ButtonEdit = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  button {
    font-weight: bold;

    width: 7.4rem;
    height: 2rem;
    padding-left: 1rem;

    border-radius: 14px;
    border: 1px solid var(--primary);

    background: none;
    color: var(--primary);
    position: absolute;

    transition: background 200ms;
    cursor: pointer;
  }
`;
export const ButtonDelete = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  button {
    font-weight: bold;

    width: 7.4rem;
    height: 2rem;
    padding-left: 1rem;
    border-radius: 14px;
    border: 1px solid var(--primary);

    background: none;
    color: var(--primary);
    position: absolute;

    transition: background 200ms;
    cursor: pointer;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: 1px solid var(--gray-50);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
`;

export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 30rem;
  border-radius: 1rem;
`;

export const CardContent = styled.div`
  padding: 2rem 5rem 5rem;

  p {
    font-size: 1.1rem;
    letter-spacing: normal;
  }

  h1 {
    font-size: 1.5rem;

    line-height: normal;
    margin-bottom: auto;
  }
  h2 {
    font-size: 1.25rem;
    line-height: normal;
  }
  h3 {
    font-size: 1.125rem;
    line-height: normal;
  }
  strong {
    font-family: "Visby-demi-bold";
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: var(--primary);

    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 2rem 5rem 5rem;
  }
  @media screen and (max-width: 700px) {
    padding: 1rem;

    li {
      margin-left: 2rem;
    }
  }
`;

export const Share = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  h3 {
    margin-bottom: 1rem;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BoxTwitter = styled.a`
  background-color: black;
  border-radius: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 300ms;
  height: 2.2rem;

  :hover {
    background: var(--primary);
  }
  @media screen and (max-width: 768px) {
    height: 1.75rem;
  }
`;

const IconCSS = css`
  width: 2.2rem;
  height: 2.2rem;

  cursor: pointer;

  fill: var(--white);
  transition: background 300ms;

  @media screen and (max-width: 768px) {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const FacebookIcon = styled(Facebook)`
  background-color: black;
  border-radius: 1.2rem;
  padding: 0.4rem;
  ${IconCSS};
  :hover {
    background: var(--primary);
  }
`;
export const TwitterIcon = styled(Twitter)`
  padding: 0.4rem;
  ${IconCSS};
`;

const IconCSSEditors = css`
  width: 1.4rem;
  height: 1.4rem;
  left: 14px;
  padding-left: 0.2rem;

  color: var(--primary);
  cursor: pointer;
`;

export const EditIcon = styled(EditAlt)`
  position: absolute;
  ${IconCSSEditors}
`;

export const TrashIcon = styled(TrashAlt)`
  position: absolute;
  ${IconCSSEditors}
`;

export const ArrowRightIcon = styled(ArrowRight)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  rotate: calc(180deg);

  transition: color 200ms ease-in-out;
  :hover {
    color: var(--primary);
  }
`;

export const Preview = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const PreviewInPage = styled.div`
  width: 10rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-88);

  span {
    margin-bottom: 5px;
  }
`;

export const ImgPreviewInPage = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 0.2rem;
  object-fit: cover;
  margin-bottom: 1rem;
`;

export const ContainerPreviewPost = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 2rem;
  margin-top: 5rem;
  text-decoration: none;

  @media screen and (max-width: 1024px) {
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    padding: 0 2rem;
  }
`;
