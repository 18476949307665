import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContentText = styled.div`
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.3rem;
    color: var(--gray-95);
    text-align: start;
    letter-spacing: 0rem;
    line-height: 1.5rem;

    @media screen and (max-width: 768px) {
      text-align: justify;
      font-size: 0.9rem;
    }
  }
  .franchising {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 1.5rem;
      font-family: "Visby-demi-bold";
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1rem;
      }
    }
  }

  .update {
    display: flex;
    justify-content: end;
    @media screen and (max-width: 1400px) {
      justify-content: center;
    }
    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  ol {
    font-family: "Visby-regular";
    font-size: 1.2rem;
    padding: 1rem;
    margin-left: 1rem;
    color: var(--gray-95);
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem 2.5rem;
  }
`;

export const Title = styled.h1`
  @media screen and (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.h5`
  font-family: "Visby";
  font-weight: bold;
  font-size: 2rem;

  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
  }
`;
