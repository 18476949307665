import React, { useEffect } from "react";
import {
  Modal,
  ModalClose,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Title,
  ModalContainer,
} from "./styles";

interface IModalProps {
  isActive: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  title: string | React.ReactNode;
  handleClose: (open: boolean) => void;
}

export const CustomModal: React.FC<IModalProps> = ({
  isActive,
  children,
  footer,
  title,
  handleClose,
}) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleClose]);

  return (
    <Modal isActive={isActive}>
      <ModalContainer className="modalContainer">
        <ModalHeader className="modalHeader">
          <Title className="modalTitle">{title}</Title>
          <ModalClose className="modalClose" onClick={() => handleClose(false)}>
            X
          </ModalClose>
        </ModalHeader>
        <ModalBody className="modalBody">{children}</ModalBody>
        <ModalFooter className="modalFooter">{footer}</ModalFooter>
      </ModalContainer>
    </Modal>
  );
};
