import React from "react";
import GlobalStyle from "./styles/globalStyles";
import Router from "./routes/router";
import "react-quill/dist/quill.snow.css";
import "./datadog"

function App() {
  return (
    <>
      <GlobalStyle />
      <Router />
    </>
  );
}

export default App;
