import { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";

import ReactQuill from "react-quill";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import "moment/locale/pt-br";
import Swal from "sweetalert2";

import { IPosts } from "../../model/posts";

import apiThor from "../../services/apiThor";
import s3handlerApi from "../../services/s3handlerApi";
import { hasPermissions } from "../../services/user";

import { Spinner } from "../../components/Spinner";
import { CustomModal } from "../../components/Modal";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import {
  BoxTwitter,
  Card,
  CardContent,
  Container,
  FacebookIcon,
  Img,
  Share,
  SocialMedia,
  Titles,
  TwitterIcon,
  Publishers,
  EditIcon,
  ButtonEdit,
  ButtonDelete,
  TrashIcon,
  ArrowRightIcon,
  HeaderPost,
  PreviewInPage,
  ImgPreviewInPage,
  Preview,
  ContainerPreviewPost,
} from "./styles";
import {
  BoxImg,
  Button,
  ContainerPreview,
  ImgPreview,
  Mandatory,
} from "../Blog/styles";
import { Helmet } from "react-helmet-async";
import { Post } from "../../components/Post";

export const SinglePost: React.FC<IPosts> = ({
  title,
  url_image,
  subtitle,
  created_at,
  description,
  user_name,
  message,
  ...rest
}) => {
  const { slug } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const [shouldOpenPostModalEdit, setShouldOpenPostModalEdit] = useState(false);

  const navigate = useNavigate();

  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [previousPost, setPreviousPost] = useState<IPosts[]>([]);
  const [post, setPost] = useState<IPosts | null>(null);
  const [editPostData, setEditPostData] = useState<IPosts>({
    title: post?.title || "",
    subtitle: post?.subtitle,
    description: post?.description,
    url_image: post?.url_image,
    s3_key: post?.s3_key,
    mime_type: post?.mime_type,
    id: post?.id,
  });

  useEffect(() => {
    const getSinglePost = async () => {
      try {
        setReload(false);
        setLoading(true);
        const {
          data: { content: response },
        } = await apiThor.get(`/blog/${slug}`);
        setPost(response);
        setPreviousPost(state?.previousPost);
        setEditPostData((prevState) => ({
          ...prevState,
          title: response?.title || "",
          subtitle: response?.subtitle,
          description: response?.description,
          url_image: response?.url_image,
          s3_key: response?.s3_key,
          mime_type: response?.mime_type,
          id: response?.id,
        }));
      } catch (error: any) {
        Swal.fire({
          title: "Ocorreu um erro!",
          text: "Post do blog não encontrado",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/blog");
          }
        });
      } finally {
        setLoading(false);
      }
    };
    if (reload) {
      getSinglePost();
    }
  }, [reload, slug, navigate, state]);

  const handleEditPost = async () => {
    setLoading(true);
    try {
      let payload = editPostData;
      if (image) {
        const imgToUpload = new FormData();
        imgToUpload.append("file", image);

        const {
          data: { location, key },
        } = await s3handlerApi.post(
          "/s3-upload/upload/amadelli-blog-attachments",
          imgToUpload
        );

        payload.s3_key = key;
        payload.url_image = location;
      }
      await apiThor.put(`/blog/${post?.id}`, payload);

      Swal.fire({
        icon: "success",
        title: "Post editado com sucesso!",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          setShouldOpenPostModalEdit(false);
          setReload(true);
          setLoading(true);
          navigate("/blog");
        }
      });
    } catch (error: any) {
      Swal.fire({
        title: "Ocorreu um erro!",
        text: "",
      });
      setLoading(false);
    }
  };

  const handleDeletePost = async (id: number | undefined) => {
    try {
      const result = await Swal.fire({
        title: "Tem certeza que deseja deletar essa postagem?",
        text: "Essa ação é irreversível!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero deletar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          setLoading(true);
          await apiThor.delete(`blog/${id}`);
          Swal.fire("Sucesso!", "O post foi deletado com sucesso");
          setLoading(false);
          navigate("/blog");
        } catch (error) {
          console.log(error);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCleanInput = () => {
    let fileCleanInput = document.getElementById(
      "fileInput"
    ) as HTMLInputElement;
    if (fileCleanInput) {
      fileCleanInput.value = "";
    }
    setImage(null);
    setImagePreview(null);
  };

  const formattedDescription = () => {
    return { __html: post?.description || "" };
  };

  return (
    <>
      <Helmet>
        <title>{`The Best Açaí - ${post?.title}`}</title>
        <link
          rel="canonical"
          href={`https://thebestacai.com.br/blog/${slug}`}
        />
        <meta property="og:image" content={post?.url_image} />
        <meta property="og:type" content="article" />
        <meta name="description" content={post?.subtitle} />
        <meta property="og:description" content={post?.subtitle} />

        <meta property="og:title" content={post?.title} />
        <meta
          property="og:url"
          content={`https://thebestacai.com.br/blog/${slug}`}
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:url"
          content={`https://thebestacai.com.br/blog/${slug}`}
        />
        <meta name="twitter:title" content={post?.title} />
        <meta name="twitter:description" content={post?.subtitle} />
        <meta name="twitter:image" content={post?.url_image} />
      </Helmet>
      <Navbar />
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <HeaderPost>
            <ArrowRightIcon
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Voltar"
              onClick={() => navigate("/blog")}
            ></ArrowRightIcon>
            <Tooltip id="my-tooltip" place="bottom" />

            <Publishers>
              {hasPermissions("blog_edit") && (
                <ButtonEdit>
                  <button onClick={() => setShouldOpenPostModalEdit(true)}>
                    Editar
                  </button>
                  <EditIcon onClick={() => setShouldOpenPostModalEdit(true)} />
                </ButtonEdit>
              )}

              {hasPermissions("blog_delete") && (
                <ButtonDelete>
                  <button onClick={() => handleDeletePost(post?.id)}>
                    Excluir
                  </button>
                  <TrashIcon onClick={() => handleDeletePost(post?.id)} />
                </ButtonDelete>
              )}
            </Publishers>
          </HeaderPost>
          {(hasPermissions("blog_delete") || hasPermissions("blog_edit")) && (
            <hr />
          )}
          <Titles>
            <h1>{post?.title}</h1>
            <h2>{post?.subtitle}</h2>
            <span>{moment(post?.created_at).format("DD/MM/YYYY")}</span>

            <p>
              Atualizado {moment(post?.updated_at).locale("pt-br").fromNow()}
            </p>
          </Titles>
          <Card>
            <Img src={post?.url_image} alt="Imagem do Post" />
            <CardContent>
              <p>
                {post?.description && (
                  <div dangerouslySetInnerHTML={formattedDescription()} />
                )}
              </p>
            </CardContent>
          </Card>
          <Share>
            <h3>Compartilhe</h3>

            <SocialMedia>
              <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=https://thebestacai.com.br/blog/${slug}`}
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
              <BoxTwitter
                target="_blank"
                href={`https://twitter.com/intent/tweet?url=https://thebestacai.com.br/blog/${slug}`}
                rel="noreferrer"
              >
                <TwitterIcon />
              </BoxTwitter>
            </SocialMedia>
          </Share>
          <ContainerPreviewPost>
            {previousPost && (
              <>
                {previousPost
                  .filter((post_previous) => post_previous.id !== post?.id)
                  .slice(0, 3)
                  .map((postPrevious) => (
                    <Link
                      onClick={() => setReload(true)}
                      to={`/blog/${postPrevious.slug}`}
                      className="postLink"
                      key={postPrevious.title}
                      state={{
                        previousPost: previousPost,
                      }}
                    >
                      <Post
                        title={postPrevious.title}
                        subtitle={postPrevious.subtitle}
                        url_image={postPrevious.url_image}
                        created_at={postPrevious.created_at}
                      />
                    </Link>
                  ))}
              </>
            )}
          </ContainerPreviewPost>
          <CustomModal
            isActive={shouldOpenPostModalEdit}
            title="Editar postagem"
            handleClose={() => setShouldOpenPostModalEdit(false)}
            key={1}
          >
            <div className="modalContainer">
              <form className="modal">
                <label htmlFor="title">Título</label>
                <input
                  className="titleInput"
                  type="text"
                  name="title"
                  maxLength={100}
                  value={editPostData.title}
                  placeholder="Digite o título..."
                  onChange={(event) =>
                    setEditPostData({
                      ...editPostData,
                      title: event.target.value,
                    })
                  }
                />
                <div className="required">
                  <span>Máximo 100 caracteres</span>
                </div>

                <label htmlFor="subtitle">Subtitulo</label>
                <input
                  className="titleInput"
                  type="text"
                  maxLength={150}
                  name="subtitle"
                  value={editPostData.subtitle}
                  placeholder="Digite o subtítulo..."
                  onChange={(event) =>
                    setEditPostData({
                      ...editPostData,
                      subtitle: event.target.value,
                    })
                  }
                />
                <div className="required">
                  <span>Máximo 150 caracteres</span>
                </div>
                <BoxImg>
                  <Mandatory>Obrigatório *</Mandatory>
                  <p>Tamanho máx recomendado: 1 MB </p>
                  <p>
                    Resolução recomendada: <strong> 1240x830</strong>
                  </p>
                </BoxImg>

                <label htmlFor="fileInput" className="fileLabel" tabIndex={0}>
                  <span className="fileSpan">Selecione a imagem</span>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  id="fileInput"
                  onChange={({ target: { files } }) => {
                    if (files && files.length > 0) {
                      const selectedFiles = files[0];
                      const inputSize = 300 * 1024;
                      if (selectedFiles.size <= inputSize) {
                        setImage(selectedFiles);

                        const reader = new FileReader();
                        reader.onload = (preview) => {
                          // @ts-ignore
                          setImagePreview(preview.target.result as string);
                        };
                        reader.readAsDataURL(selectedFiles);
                      } else {
                        Swal.fire({
                          text: "Tamanho de imagem inválido",
                        });
                      }
                    }
                  }}
                />
                <Preview>
                  <PreviewInPage>
                    <ImgPreviewInPage
                      src={editPostData.url_image}
                      alt="Imagem Selecionada"
                      className="imagePreview"
                    />
                    <span>Preview da imagem que está na página</span>
                  </PreviewInPage>
                  {imagePreview && (
                    <ContainerPreview>
                      <ImgPreview
                        src={imagePreview}
                        alt="Imagem Selecionada"
                        className="imagePreview"
                      />
                      <Button onClick={handleCleanInput}>Excluir</Button>
                    </ContainerPreview>
                  )}
                </Preview>

                <label htmlFor="text">Descrição</label>
                <ReactQuill
                  value={editPostData.description}
                  onChange={(value) =>
                    setEditPostData({ ...editPostData, description: value })
                  }
                  id="description"
                />

                {loading ? (
                  <Spinner />
                ) : (
                  <div className="boxButton">
                    <Button type="submit" onClick={handleEditPost}>
                      Salvar post
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </CustomModal>
        </Container>
      )}

      <Footer active={true} />
    </>
  );
};
