import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  Circle,
} from "@react-google-maps/api";
import axios from "axios";

import { Container } from "./styles";

export const StoreMap: React.FC = () => {
  const [points, setPoints] = useState<
    { company: string; lat: number; lng: number }[]
  >([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  useEffect(() => {
    async function fetchAllCompanies() {
      try {
        const {
          data: {
            content: { companies },
          },
        } = await axios.get(
          "https://prd-amatech.com/api/janus/company/600+86050460/distance"
        );
        const response = //@ts-ignore
          companies?.map((company) => ({
            company: company.company,
            lat: company.lat,
            lng: company.lng,
          }));

        setPoints(response);
      } catch (e: any) {
        console.log(e);
      }
    }
    fetchAllCompanies();
  }, []);

  return (
    <>
      {isLoaded && points.length ? (
        <Container>
          <GoogleMap
            zoom={4}
            center={{ lat: -14.2400732, lng: -53.1805017 }}
            mapContainerClassName="map-container"
          >
            {points.map((point, index) => (
              <React.Fragment key={index}>
                <MarkerF
                  key={index}
                  title={point.company}
                  position={{ lat: point.lat, lng: point.lng }}
                />

                <Circle
                  key={index}
                  visible={true}
                  draggable={false}
                  editable={false}
                  radius={2000}
                  center={{ lat: point.lat, lng: point.lng }}
                  options={{
                    center: { lat: point.lat, lng: point.lng },
                    strokeColor: "#ffbebe",
                    fillColor: "#ffbebe",
                    fillOpacity: 0.3,
                  }}
                />
              </React.Fragment>
            ))}
          </GoogleMap>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};
