import moment from "moment";

import { IPosts } from "../../model/posts";
import { Card, Container, ContainerContent, Img } from "./styles";

export const Post: React.FC<IPosts> = ({
  url_image,
  title,
  subtitle,
  created_at,
}) => {
  return (
    <>
      <Container>
        <Card>
          <div>
            <Img src={url_image} alt="Imagem da postagem" />
          </div>
          <ContainerContent>
            <div className="titles">
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </div>

            <span> {moment(created_at).format("DD/MM/YYYY")}</span>
          </ContainerContent>
        </Card>
      </Container>
    </>
  );
};
