import styled, { css } from "styled-components";
interface IProps {
  isActive: boolean;
}
export const Modal = styled.div<IProps>`
  ${({ isActive }) => {
    if (isActive) {
      return css`
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop: static;
      `;
    } else {
      return css`
        display: none;
      `;
    }
  }}
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  width: 100%;
  height: 100%;
  min-height: 30%;
  background-color: var(--white);

  @media screen and (max-width: 400px) {
    min-width: 70%;
    min-height: 60%;
  }
  @media screen and (max-width: 340px) {
    min-width: 60%;
    min-height: 50%;
  }
`;
export const ModalHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 95% 5%;
  width: 100%;

  padding: 1%;
`;
export const ModalBody = styled.div`
  width: 100%;
  padding: 1%;
  overflow-y: auto;
`;
export const ModalFooter = styled.div`
  width: 100%;
  padding: 1%;
`;
export const ModalClose = styled.button`
  width: 1.2rem;
  height: 1.2rem;

  background: none;
  border: none;
  box-shadow: none;

  font-size: 1.2rem;

  :hover {
    cursor: pointer;
  }
  :active,
  ::selection {
    background: var(--white);
    border: none;
    box-shadow: none;
  }
`;

export const Title = styled.h3`
  margin: 0;
`;
