import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import apiJanus from "../../services/apiJanus";
import allBrazilStates from "../../helpers/allBrazilStates.json";
import { BannerHome } from "../../components/Banners";
import { Navbar } from "../../components/Navbar";
import { Spinner } from "../../components/Spinner";
import { Footer } from "../../components/Footer";
import {
  BoxLeftContent,
  Container,
  ContentSelect,
  ContentStores,
  SectionSearch,
  StoreBox,
  StoreImage,
  TopContent,
  UnselectedStore,
  ContentInfo,
  DefaultImage,
} from "./styles";
import { Helmet } from "react-helmet-async";

interface Store {
  id: number;
  company_name: string;
  state_registration: string;
  city: string;
  address: string;
  district: string;
  description_delivery_plataform: string;
  description_delivery_time: string;
  description_store_time: string;
  whatsapp_number: string;
  complement: string;
  number: string;
  telefone: string;
  cep: string;
  image: string;
}

type City = {
  name: string;
  state_registration: string;
};

type State = {
  nome: string;
  sigla: string;
};

export const Lojas = () => {
  const LojaImg = require("../../assets/Images/Banners_site/banner-lojas.webp");
  const defaultStore = require("../../assets/Images/icone_loja_default.webp");
  const ImgMobile = require("../../assets/Images/Banners-mobile/banner-lojas.webp");

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<City[]>([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStates, setFilteredStates] = useState<State[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    const fecthStates = async () => {
      try {
        const {
          data: {
            content: { state, citys },
          },
        } = await apiJanus.get(`/company/amabest-club/citys_and_state`);

        setCities(citys);

        const formattedState = allBrazilStates.filter((allState) =>
          state.includes(allState.sigla)
        );

        setFilteredStates(formattedState);
      } catch (error: any) {
        Swal.fire({
          title: "Ocorreu um erro!",
          text: "Parece que as cidades não estão disponíveis no momento.",
          timer: 4000,
        });
      }
    };

    fecthStates();
  }, []);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await apiJanus.get(`/company/amabest-club?city=${selectedCity}`);

        setStores(content);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCity) {
      fetchStores();
    }
  }, [selectedCity]);

  const handleCities = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLoading(true);

    setSelectedState(event.target.value);

    const formattedCity = cities.filter(
      (city) => city.state_registration === event.target.value
    );

    setFilteredCities(formattedCity);
    setLoading(false);

    if (!formattedCity.length) {
      return;
    }
  };

  return (
    <>
      <Helmet>
        <title>The Best Açaí - Nossas Lojas</title>
        <link rel="canonical" href={`https://thebestacai.com.br/lojas`} />
        <meta property="og:image" content={LojaImg} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={LojaImg} />
      </Helmet>
      <Navbar />
      <BannerHome
        img={LojaImg}
        alt="Banner da página nossas lojas. Na foto, imagens internas de uma loja"
        text={false}
        imgMobile={ImgMobile}
      />
      <Container>
        <TopContent>
          <SectionSearch>
            <h1>Nossas lojas</h1>

            <ContentSelect>
              <select
                placeholder="Selecione um Estado"
                name="state"
                id="state"
                onChange={(event) => handleCities(event)}
              >
                <option value="0" className="optionInitial">
                  Selecione o Estado
                </option>
                {filteredStates.map((state) => (
                  <option key={state.nome} value={state.sigla}>
                    {state.nome}, {state.sigla}
                  </option>
                ))}
              </select>

              <select
                placeholder="Selecione uma cidade"
                name="city"
                id="city"
                disabled={!selectedState}
                onChange={(event) => {
                  setSelectedCity(event.target.value);
                }}
              >
                <option value="0" className="optionInitial">
                  Selecione a cidade
                </option>
                {filteredCities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </ContentSelect>
          </SectionSearch>
        </TopContent>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {stores.length !== 0 ? (
              <ContentStores>
                {stores.map((store) => (
                  <>
                    <StoreBox>
                      {!store?.image ? (
                        <DefaultImage
                          src={defaultStore}
                          alt="Imagem ilustrativa de uma loja"
                        />
                      ) : (
                        <StoreImage src={store?.image} alt="Imagem das lojas" />
                      )}
                      <BoxLeftContent>
                        <h4>
                          {store?.address}, {store?.number} - {store?.district}{" "}
                          {store?.complement ? "- " + store?.complement : ""}
                        </h4>
                        <ContentInfo>
                          <h6>
                            ⏰ <strong>Horário:</strong>{" "}
                            {store?.description_store_time
                              ? store?.description_store_time
                              : "Não informado"}
                          </h6>

                          <h6>
                            🛵 <strong>Delivery:</strong>{" "}
                            {store?.description_delivery_time
                              ? store?.description_delivery_time
                              : "Não informado"}
                          </h6>

                          <h6>
                            📱 <strong>Plataformas:</strong>{" "}
                            {store?.description_delivery_plataform
                              ? store?.description_delivery_plataform
                              : "Não informado"}
                          </h6>

                          <h6>
                            📞 <strong>Phone:</strong>{" "}
                            {store?.telefone
                              ? store?.telefone?.length === 11
                                ? `(${store?.telefone?.slice(
                                    0,
                                    2
                                  )}) ${store?.telefone?.slice(
                                    2,
                                    7
                                  )} - ${store?.telefone?.slice(7, 11)}`
                                : `(${store?.telefone?.slice(
                                    0,
                                    2
                                  )}) ${store?.telefone?.slice(
                                    2,
                                    6
                                  )} - ${store?.telefone?.slice(6, 10)}`
                              : "Não informado"}
                          </h6>

                          <h6>
                            📞 <strong>Whatsapp:</strong>{" "}
                            {store?.whatsapp_number ? (
                              <a
                                href={`https://api.whatsapp.com/send?phone=55${store?.whatsapp_number}`}
                                target="blank"
                              >
                                ({store?.whatsapp_number?.slice(0, 2)}){" "}
                                {store?.whatsapp_number?.slice(2, 7)} -{" "}
                                {store?.whatsapp_number?.slice(7, 11)}
                              </a>
                            ) : (
                              "Não informado"
                            )}
                          </h6>
                        </ContentInfo>
                      </BoxLeftContent>
                    </StoreBox>
                  </>
                ))}
              </ContentStores>
            ) : (
              <>
                {filteredCities !== null ? (
                  <UnselectedStore>
                    Selecione um Estado e a cidade que deseja visualizar nossas
                    lojas!
                  </UnselectedStore>
                ) : (
                  <UnselectedStore>nao há lojas nessa cidade</UnselectedStore>
                )}
              </>
            )}
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};
