import React, { useState } from "react";

import {
  Container,
  ListOfLinks,
  Logo,
  MobileIcon,
  Nav,
  NavItem,
  NavLinks,
} from "./styles";
import { FaBars, FaTimes } from "react-icons/fa";

export const Navbar: React.FC = () => {
  const LogoNav = require("../../assets/Images/logo_nav.webp");

  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <Container>
      <Nav>
        <Logo
          src={LogoNav}
          alt="Logo do The best açaí"
          onClick={() => (window.location.href = "/")}
        />

        <ListOfLinks open={click} onClick={handleClick}>
          <NavItem>
            <NavLinks to="/">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>Início</span>
              )}
            </NavLinks>
            <NavLinks to="/sobre">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Nossa história
                </span>
              )}
            </NavLinks>
            <NavLinks to="/lojas">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Nossas lojas
                </span>
              )}
            </NavLinks>
            <NavLinks to="/blog">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>Blog</span>
              )}
            </NavLinks>
            <NavLinks to="/trabalhe-conosco">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Trabalhe conosco
                </span>
              )}
            </NavLinks>
            <NavLinks to="/thebest-club">
              {({ isActive }) => (
                <span className={isActive ? "active" : "inactive"}>
                  Clube The Best
                </span>
              )}
            </NavLinks>
            <a
              className="buttonFranchisee"
              target="_blank"
              rel="noreferrer"
              href="https://franquia.thebestacai.com.br/sejaumfranqueado
 "
            >
              Seja um Franqueado
            </a>
          </NavItem>
        </ListOfLinks>

        <MobileIcon onClick={handleClick}>
          {click ? (
            <FaTimes color="var(--white)" />
          ) : (
            <FaBars color="var(--white)" />
          )}
        </MobileIcon>
      </Nav>
    </Container>
  );
};
