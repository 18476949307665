import React from "react";
import { SpinnerContent } from "./styles";

import animationData from "./animationData.json";

export const Spinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <SpinnerContent
        options={defaultOptions}
        height={120}
        width={120}
        style={{ color: "var(--primary)" }}
      />
    </div>
  );
};
